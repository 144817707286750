import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Phnom_Penh')
moment.updateLocale('en', {
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค',
  ],
  // weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  weekdaysMin: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
})

export class Timer {
  toMomentOffset(val) {
    const init = val ? val.clone() : moment()

    return init
  }

  get(init, format) {
    if (init && format) return moment(init, format)
    else if (init) return moment(init)

    return this.toMomentOffset()
  }

  getDate() {
    const val = moment().startOf('day')
    return this.toMomentOffset(val)
  }

  getNoMinute() {
    return this.toMomentOffset().minute(0).second(0)
  }

  format() {
    return this.toMomentOffset().format()
  }

  toThatDate(val, time = false) {
    const date = this.toMomentOffset(val)
    const text_date = date.format('DD/MM/') + `${date.year()}`

    const text_time = time ? ` ${date.format('HH:mm')}` : ''
    return text_date + text_time
  }

  getUnix (init, format) {
    return this.get(init, format).unix()
  }

  convertToMoment(val, format) {
    try {
      if (val === '' || val === undefined || val === null) return undefined
      else if (moment.isMoment(val)) return val

      return moment(val, format)
    } catch (e) {
      console.log('to moment:', e.message)
    }

    return undefined
  }
}

export const timer = new Timer()
export default timer
