import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Chart from 'chart.js/auto'

const ChartView = (props) => {
  const [chart, setChart] = useState(undefined)
  const [node, setNode] = useState(undefined)
  const { id, code, data = {} } = props

  const onRef = useCallback((ctx) => {
    if (ctx) {
      setNode(ctx)
    }
  }, [])

  useEffect(() => {
    if (node) {
      if (chart) {
        chart.destroy()
      }
      const myChart = new Chart(node, data)
      setChart(myChart)
    }
  }, [node, code])

  return (
    <PageView>
      <Canvas id={id} ref={onRef} />
    </PageView>
  )
}

const PageView = styled.div`
  padding-right: 4px;
`

const Canvas = styled.canvas`
  width: 100%;
`

export default ChartView
