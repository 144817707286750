import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

const Loading = (props) => {
  const { loading = false, children } = props

  if (loading === false) return children || <div />

  return (
    <Display>
      <ReactLoading type={'spinningBubbles'} color={'#305FED'} height={200} width={160} />
    </Display>
  )
}

const Display = styled.div`
  div {
    margin: 0 auto;
    margin-top: 60px;
  }
`

export default Loading
