import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { GoogleBtn } from 'components/button'
import { Marget } from 'icon'
import { message } from 'utils'

const LoginPage = (props) => {
  const [loading, setLoading] = useState({})

  const onGoogle = async () => {
    try {
      setLoading({ google: true })
      await props.member.loginByGoogle()
    } catch (e) {
      message.error(e.message)
    }
    setLoading({})
  }

  return (
    <PageView>
      <Login>
        <Logo>
          <Marget size={2} />
        </Logo>
        <Text>
          เข้าสู่ระบบ
        </Text>

        <LoginOption>
          <GoogleBtn
            loading={loading.google}
            text="เข้าสู่ระบบผ่าน gmail"
            onClick={onGoogle}
          />
        </LoginOption>
      </Login>
    </PageView>
  )
}

const PageView = styled.div`
  width: 1440px;
  padding: 0px 40px;
  margin: 0 auto;
`

const Logo = styled.div`
  width: 100%;
  text-align: center;
`

const Text = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  color: grey;
`

const Login = styled.div`
  width: 100%;
  max-width: 425px;
  padding: 40px;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: white;

  margin: 0 auto;
  margin-top: 120px;
`

const LoginOption = styled.div`
  width: 100%;
  margin-top: 40px;
`

export default inject('member')(observer(LoginPage))
