import { Picture } from 'components/display'
import { ImgPicker } from 'components/picker'

const ImgInput = (props) => {
  const { name, value, onChange } = props

  const onRemove = () => {
    onChange('', name)
  }

  const img = value || ''
  if (img === '') {
    return (
      <ImgPicker
        name={name}
        onSelect={onChange}
      />
    )
  }

  return (
    <Picture
      img={img}
      onRemove={onRemove}
    />
  )
}

export default ImgInput
