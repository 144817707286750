import styled from 'styled-components'

import Title from '../Title'

const PaymentInfo = (props) => {
  const { order = {} } = props
  const payment_url = order.payment_url || ''

  const content = payment_url !== ''
    ? <Picture src={payment_url} />
    : <Detail>ยังไม่มีการชำระ</Detail>

  return (
    <PageView>
      <Title text="ข้อมูลการชำระเงิน" />
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 16px;
`

const Detail = styled.div`
  font-size: 14px;
  line-height: 14px;
`

const Picture = styled.img`
  width: 100%;
  max-height: 200px;
  background-color: ${(p) => p.theme.color.disable};
`

export default PaymentInfo
