const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyBu_lo_DhJ9T5_sjnUaTb45TtHLWyOufdk',
    authDomain: 'marget-dev.firebaseapp.com',
    projectId: 'marget-dev',
    storageBucket: 'marget-dev.appspot.com',
    messagingSenderId: '215000958445',
    appId: '1:215000958445:web:86e9849724069674ee53e4',
    measurementId: 'G-XEN8S0488B',
  },
  api: 'https://api-test.marget.app/api',
  limit: {
    file: 1600000,
  },
  version: '0.1',
}

module.exports = config
