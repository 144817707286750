import styled from 'styled-components'

const TextTag = (props) => {
  const { text, onClick, tag } = props

  const click = () => onClick(tag)
  return (
    <Text>
      {text}
      <i onClick={click} className="fas fa-times" />
    </Text>
  )
}

const Text = styled.span`
  font-size: 16px;
  border: 1px solid ${(p) => p.theme.color.grey};
  background-color: white;
  padding: 2px 8px;
  border-radius: 8px;
  margin: 0px 4px 4px 0px;

  box-sizing: border-box;

  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  line-height: 20px;
  white-space: nowrap;
  opacity: 1;
  transition: all 0.3s;

  i {
    margin-left: 8px;
    cursor: pointer;
  }
`


export default TextTag
