import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './assets'

import theme from 'theme'
import store from 'stores'
import { history } from 'utils'

import App from './App'

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter history={history}>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
