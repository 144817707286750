import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Content } from 'components/display'
import Table from 'components/table'
import Loading from 'components/loading'
import { PrimaryBtn } from 'components/button'
import { RemoveBtn } from 'components/circle'
import { message, format } from 'utils'


import MarketMenu from '../Menu'

import AddDialog from './AddDialog'

const MarketInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)

  const { market_id } = useParams()

  const onGetDoc = useCallback(async (id) => {
    try {
      setLoading(true)
      await props.marketMember.getMemberList(id)
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.marketMember])

  useEffect(() => {
    onGetDoc(market_id)
  }, [onGetDoc, market_id])

  const onAdd = () => setVisible(true)
  const onClose = () => setVisible(false)

  const onRemove = async (user) => {
    try {
      setSaving(true)
      await props.marketMember.removeMember(market_id, user)
    } catch (e) {
      message.error(e.message)
    }
    setSaving(false)
  }

  const { member_list } = props.marketMember.toJS()

  const onMobile = (item) => {
    return format.toPattern(item.mobile || '', 'xxx-xxx-xxxx')
  }

  const onButton = (item) => {
    return (
      <RemoveBtn onClick={() => onRemove(item)} />
    )
  }

  const columns = [
    {
      title: 'ชื่อ',
      key: 'name',
      size: {
        lg: 8, md: 12, xs: 21
      }
    },
    {
      title: 'นามสกุล',
      key: 'surname',
      size: {
        lg: 8, md: 12, xs: 21
      }
    },
    {
      title: 'เบอร์โทร',
      render: onMobile,
      size: {
        lg: 6, md: 12, xs: 21
      }
    },
    {
      render: onButton,
      size: {
        lg: 2, md: 12, xs: 21
      }
    },
  ]

  return (
    <Content>
      <MarketMenu id={market_id} selected={'member'}>
        <Loading loading={loading}>
          <Table list={member_list} columns={columns} />

          <Btn>
            <PrimaryBtn
              loading={saving}
              text="เพิ่มสมาชิก"
              onClick={onAdd}
            />
          </Btn>
          <AddDialog
            market_id={market_id}
            visible={visible}
            onClose={onClose}
          />
        </Loading>
      </MarketMenu>
    </Content>
  )
}

const Btn = styled.div`
  width: 140px;
  padding: 25px 0px;
`

export default inject('marketMember')(observer(MarketInfo))
