import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Input } from 'components/input'
import Loading from 'components/loading'
import BaseDialog from 'dialog/BaseDialog'

import UserItem from './UserItem'

const AddDialog = (props) => {
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const [err, setErr] = useState(undefined)
  const { market_id, visible, onClose } = props

  const onChange = async (val) => {
    setText(val)
    if (val.length < 3) {
      return
    }
    try {
      setLoading(true)
      setErr(undefined)
      await props.marketMember.findMemberList(val)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const { user_list } = props.marketMember.toJS()

  const content = user_list.map((it, i) => {
    return <UserItem key={i} market_id={market_id} user={it} />
  })

  return (
    <BaseDialog visible={visible} onClose={onClose}>
      <Content>
        <Input
          label="เบอร์โทร"
          value={text}
          onChange={onChange}
          isNumber={true}
          pattern="xxx-xxx-xxxx"
          invalid={err}
        />

        <Title>รายชื่อ</Title>

        <Loading loading={loading}>
          <UserList>
            {content}
          </UserList>
        </Loading>
      </Content>
    </BaseDialog>
  )
}

const Content = styled.div`
  width: 100%;
  min-width: 580px;
  min-height: 40vh;
  max-height: 80vh;
  color: ${(props) => props.theme.color_level.grey.high};
`

const Title = styled.div`
  font-size: 16px;
  margin: 20px 0px;
  color: ${(props) => props.theme.color.blue};
`

const UserList = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.color_level.grey.high};
`

export default inject('marketMember')(observer(AddDialog))
