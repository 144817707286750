import Joi from 'joi'

import { validator } from 'utils'

const role = Joi.object({
  name: validator.getRuleString('ชื่อตลาด'),
  price: validator.getRuleNumber('ราคา'),
}).options({ allowUnknown: true })

export default role
