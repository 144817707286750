import React from 'react'
import styled from 'styled-components'

const Image = (props) => {
  const { img_url } = props
  return (
    <PageView>
      <Img src={img_url} />
    </PageView>
  )
}
const PageView = styled.div`
  width: 100%;
`


const Img = styled.img`
  width: auto;
  height: 40px;
  border-radius: 5px;
`

export default Image
