import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row } from 'components/display'

import Col from './Col'
import Card from './Card'

const Counter = (props) => {
  const { counter } = props.dashboard.toJS()
  return (
    <PageView>
      <Row>
        <Col { ...col }>
          <Card label="จำนวนการจองสำเร็จ" value={counter.total_bookin_completed} />
        </Col>
        <Col { ...col }>
          <Card label="จำนวนยกเลิกการจอง" value={counter.total_booking_cancelled} color="red" />
        </Col>
        <Col { ...col }>
          <Card label="ยอดเงินจองทั้งหมด" value={counter.total_income} />
        </Col>
        <Col { ...col }>
          <Card label="ยอดเงินมัดจำทั้งหมด" value={counter.total_deposit} />
        </Col>
      </Row>
    </PageView>
  )
}

const col = { lg: 6, md: 12, xs: 24 }

const PageView = styled.div`
  width: 100%;
`

export default inject('dashboard')(observer(Counter))
