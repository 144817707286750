import styled, { useTheme } from 'styled-components'
import { DatePicker as Picker } from 'antd'

import { Calendar } from 'icon'
import { timer } from 'utils'

import Invalid from './Invalid'

const MonthPicker = (props) => {
  const theme = useTheme()
  const {
    placeholder,
    disabled = false,
    value,
    clear = false,
    showTime = false,
    readonly = false,
    disablePart,
    disableFuture,
    onDateDisabled,
    invalid,
    name,
    onChange = () => {},
  } = props

  const getValue = () => {
    if (typeof value === 'string' || value instanceof String) {
      return timer.get(value)
    }

    return value
  }

  const current = getValue()

  const onValue = (val) => {
    onChange(val, name)
  }

  const onDisabled = (current) => {
    if (!current) return false

    if (disablePart) {
      return current < disablePart
    }

    if (disableFuture) {
      return current > disableFuture
    }
    return false
  }

  const onDate = () => {
    if (current) {
      const m = current.month()
      const y = current.year()
      const name = month[m]

      return `${name} ${y}`
    }
    return ''
  }

  const css = current ? 'fill' : 'empty'
  const readonlyCss = readonly ? 'picker-readonly' : ''
  const invalidCss = invalid ? 'invalid' : ''

  const color = invalid ? theme.color.error : undefined
  const disabledDate = onDateDisabled
    ? onDateDisabled
    : disablePart || disableFuture
    ? onDisabled
    : undefined
  return (
    <PageView className={`${css} ${readonlyCss} ${invalidCss}`}>
      <Label className={css}>{placeholder}</Label>
      <Picker
        placeholder={placeholder}
        disabled={disabled || readonly}
        allowClear={clear}
        disabledDate={disabledDate}
        minuteStep={5}
        className={css}
        value={current}
        onChange={onValue}
        format={onDate}
        suffixIcon={<Calendar color={color} />}
        picker="month"
      />
      <Invalid invalid={invalid} />
    </PageView>
  )
}
/*
const month = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',

  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',

  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
]
*/
const month = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}

const PageView = styled.div`
  position: relative;

  .ant-picker {
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    padding: 4px 11px;
  }

  .invalid {
    border-color: ${(p) => p.theme.color.red};
  }

  .ant-picker-suffix {
    position: absolute;
    right: 0px;
  }

  .ant-picker > .ant-picker-input > input {
    font-size: 16px;
    color: ${(p) => p.theme.color_level.grey.high};
    padding-top: 12px;
  }

  .ant-picker > .ant-picker-input > input::placeholder {
    font-size: 16px;
    color: ${(p) => p.theme.color_level.grey.high};
  }

  &.empty .ant-picker .ant-picker-input input::placeholder {
    font-size: 14px;
    color: ${(p) => p.theme.color_level.grey.high};
  }

  &.empty .ant-picker > .ant-picker-input > input {
    padding-top: 0px;
  }

  &.invalid > .ant-picker > .ant-picker-input > input::placeholder {
    color: ${(p) => p.theme.color.error};
  }
`

const Label = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.medium};
  position: absolute;
  left: 10px;
  top: 2px;
  z-index: 1;
  transition: all 0.3s ease;

  &.empty {
    display: none;
  }
`

export default MonthPicker
