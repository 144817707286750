import { format } from 'utils'

const onName = (item) => {
  const { name, surname } = item
  return `${name} ${surname}`
}

const onSaleDate = (item) => {
  const { created_at } = item
  return format.toDate(created_at)
}

const onBirthday = (item) => {
  const { birthday_at } = item
  return format.toDate(birthday_at)
}

const onInfo = (item) => {
  const { citizen_id, mobile } = item

  const id = format.toPattern(citizen_id, 'x-xxxx-xxxxx-xx-x')
  const m = format.toPattern(mobile, 'xxx-xxx-xxxx')

  const text = `เลขบัตรประชาชน: ${id} ติดต่อ: ${m}`
  return text
}

const getColumns = () => {
  const columns = [
    {
      title: 'รหัสลูกค้า',
      key: 'user_id',
      size: {
        lg: 2, md: 12, xs: 24
      }
    },
    {
      title: 'ชื่อ-นามสกุล',
      render: onName,
      size: {
        lg: 6, md: 12, xs: 24
      }
    },
    {
      title: 'จังหวัด',
      key: 'province_name',
      size: {
        lg: 4, md: 12, xs: 12
      }
    },
    {
      title: 'วันที่เกิด',
      render: onBirthday,
      size: {
        lg: 3, md: 12, xs: 24
      }
    },
    {
      title: 'วันที่สมัคร',
      render: onSaleDate,
      size: {
        lg: 3, md: 12, xs: 24
      }
    },
    {
      title: 'รายละเอียด',
      render: onInfo,
      size: {
        lg: 6, md: 12, xs: 24
      }
    },
  ]

  return columns
}

export default getColumns
