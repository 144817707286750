import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { validator, message } from 'utils'
import { Content } from 'components/display'
import { MarketGeneral } from 'components/market'
import Loading from 'components/loading'

import MarketMenu from '../Menu'
import Save from '../Save'
import rules from './rules'

const MarketEditor = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validate, setValidate] = useState({})

  const { market_id } = useParams()
  const { doc = {} } = props.market.toJS()

  const onLoad = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.market.getDoc(market_id),
        props.marketType.getList(false),
        props.province.getProvinceList(),
      ])
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.market, props.marketType, props.province, market_id])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onSave = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        validator.focus(checker.errors)
        return
      }
  
      setSaving(true)

      await props.market.updateInfo(doc)
      message.success()
    } catch (e) {
      message.error(e.message)
    }

    setSaving(false)
  }

  const onChange = (data) => {
    props.market.setMarket(data)
  }

  const onValidate = (valid = {}) => {
    setValidate({ ...valid })
  }

  return (
    <Content>
      <MarketMenu id={market_id} selected={'information'}>
        <Loading loading={loading}>
          <MarketGeneral
            doc={doc}
            validate={validate}
            onChange={onChange}
            onValidate={onValidate}
          />
        </Loading>
      </MarketMenu>
      <Save loading={saving} onClick={onSave} />
    </Content>
  )
}

export default inject(
  'market',
  'marketType',
  'province'
)(observer(MarketEditor))
