import XLSX from 'xlsx'

export class Helper {
  sleep(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  isNull(val) {
    return val === null || val === undefined
  }

  notNull(val) {
    return val !== null && val !== undefined
  }

  isValue(val) {
    return val !== null && val !== undefined && val !== ''
  }

  notValue(val) {
    return val === null || val === undefined || val === ''
  }

  isEmpty(val) {
    let list = Object.keys(val)
    return (
      val === null ||
      val === undefined ||
      val === '' ||
      list === 0 ||
      list.length === 0
    )
  }

  toPercent(a, b) {
    if (a === 0 || b === 0) return 0

    return a / b
  }

  isTextError(val, text) {
    return val === '' || val === null ? text : undefined
  }

  random({
    len,
    prefix = '',
    postfix = '',
    character = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  }) {
    let need = len - (prefix.length + postfix.length)
    let max = character.length
    if (need < 0) return
    if (max === 0) return
    let resp = ''

    for (let i = 0; i < need; i++) {
      resp += character.charAt(Math.floor(Math.random() * max))
    }
    return `${prefix}${resp}${postfix}`
  }

  getUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      if (prefix === 'http' || prefix === 'data') return val
    }

    return undefined
  }

  isUrl(val) {
    if (this.isValue(val)) {
      let prefix = val.substring(0, 4)
      return prefix === 'http'
    }

    return false
  }

  trim(data, list = []) {
    list.forEach((name) => {
      let temp = data[name]
      if (temp) data[name] = temp.trim()
    })

    return data
  }

  upItem(index, list = []) {
    const current = list[index]
    const next = list[index - 1]

    list[index] = next
    list[index - 1] = current
    return list
  }

  downItem(index, list = []) {
    const current = list[index]
    const next = list[index + 1]

    list[index] = next
    list[index + 1] = current
    return list
  }

  compareString(src, target) {
    return (
      String(src).toLocaleLowerCase() === String(target).toLocaleLowerCase()
    )
  }

  excelToJson(data = {}, name_list) {
    const result = {}
    for (const item of name_list) {
      const { name, to } = item
      result[to] = data[name] || ''
    }

    return result
  }

  excelListToJson(lines, name_list, options = {}) {
    const list = lines.map(it => this.excelToJson(it, name_list))

    const { isFilter } = options
    return isFilter ? list.filter(it => it.code !== '') : list
  }

  loadFile(file, onLoadData) {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      //const content = e.target.result
      //const lines = content.split(/\r\n|\n/)
      //const headerLine = lines.shift()
      //const headerTabs = headerLine.split('\t')

      // Parse data
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      // Get first worksheet
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      // Convert array of arrays
      const data = XLSX.utils.sheet_to_json(ws, { header: 2 })
      onLoadData(data)
    }

    reader.onerror = (e) => {
      onLoadData(null, e.target.error.name)
    }

    //reader.readAsText(file)
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }
}

export const helper = new Helper()
export default helper
