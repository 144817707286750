import { format, product } from 'utils'

const onTotal = (item) => {
  return format.toDigi(item.total)
}

const onDeposite = (item) => {
  return format.toDigi(item.deposit)
}

const onSaleDate = (item) => {
  const { start_at, finish_at } = item

  const date = product.getDate(start_at, finish_at)

  return date
}

const onShop = (item) => {
  const { name } = item
  const mobile = format.toPattern(item.mobile, 'xxx-xxx-xxxx')
  const shopInfo = item.shop_info || {}
  const text = `สินค้า: ${shopInfo.name} ติดต่อ: ${mobile} (${name})`
  return text
}

const getColumns = (onNext) => {
  const columns = [
    {
      title: 'หมายเลขการจอง',
      render: (item) => item.code.toUpperCase(),
      size: {
        lg: 3, md: 12, xs: 24
      }
    },
    {
      title: 'ตลาด',
      key: 'market_name',
      size: {
        lg: 5, md: 12, xs: 24
      }
    },
    {
      title: 'ค่าเช่า',
      render: onTotal,
      size: {
        lg: 2, md: 12, xs: 12
      }
    },
    {
      title: 'มัดจำ/ประกัน',
      render: onDeposite,
      size: {
        lg: 3, md: 12, xs: 12
      }
    },
    {
      title: 'วันที่ขาย',
      render: onSaleDate,
      size: {
        lg: 5, md: 12, xs: 24
      }
    },
    {
      title: 'ร้านค้า',
      render: onShop,
      size: {
        lg: 5, md: 12, xs: 24
      }
    },
    {
      title: '',
      render: onNext,
      align: 'center',
      size: {
        lg: 1, md: 12, xs: 12
      }
    },
  ]

  return columns
}

export default getColumns
