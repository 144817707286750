import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Booking extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
        list: [],
      },
    })
    state = this
  }

  async getList({ status, date, index = 1, per_page = 20 }) {
    const q = date ? `created_at=${date.toISOString()}` : ''
    const url = `${config.api}/v1/admin/market/order/${status}?page=${index}&&per_page=${per_page}&&${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.page.index = index
      state.display.page.per_page = per_page
      state.display.list = list
    })
  }

  async getCount({ status, date }) {
    const q = date ? `created_at=${date.toISOString()}` : ''
    const url = `${config.api}/v1/admin/market/order/${status}/counter?${q}`
    const resp = await http.get(url)

    const { total } = resp.body

    runInAction(() => {
      state.display.page.total = total || 0
    })
  }

  async cancel(id, reason) {
    const url = `${config.api}/v1/admin/market/order/${id}/cancel`
    const resp = await http.put(url, { json: { reason }})

    const { status } = resp.body

    const list = this.toJS().display.list || []
    const i = list.findIndex(it => it.order_id === id)
    let data
    if (i !== -1) {
      data = list[i]
      data.status = status
      data.market_note = reason || ''
      list[i] = data
    }

    runInAction(() => {
      state.display.list = list
    })

    return data
  }
}

export default new Booking()
