import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { Checker, InputArea } from 'components/input'
import { Col } from 'components/display'
import { PrimaryBtn, Btn } from 'components/button'

const Reason = (props) => {
  const [category, setCategory] = useState('')
  const [text, setText] = useState('')
  const [note, setNote] = useState('')

  const { loading, onConfirm, onClose } = props

  useEffect(() => {
    const item = menu[0]
    setCategory(item.value)
    setText(item.name)
    setNote('')
  }, [])

  const onChange = (v) => setNote(v)
  const onChecker = (v, name, tag) => {
    setCategory(tag.value)
    setText(tag.name)
  }

  const onClick = () => {
    const v = category === 'other' ? note : text
    onConfirm(v)
  }

  const content = menu.map((it, i) => {
    return (
      <Line key={i}>
        <Checker
          label={it.name}
          value={it.value === category}
          onChange={onChecker}
          tag={it}
        />
      </Line>
    )
  })

  const disabled = category === 'other' ? note === '' : false

  return (
    <PageView>
      <Title>เหตุผลการยกเลิกรายการจอง</Title>
      {content}
      <Note>
        <InputArea
          label="ระบุเหตุผลอื่นฯ"
          disabled={category !== 'other'}
          value={note}
          onChange={onChange}
        />
      </Note>
      <Row>
        <Col span={12} position="left">
          <Btn text="ยกเลิก" onClick={onClose} />
        </Col>
        <Col span={12} position="right">
          <PrimaryBtn disabled={disabled} loading={loading} text="ยืนยันการยกเลิก" onClick={onClick} />
        </Col>
      </Row>
    </PageView>
  )
}

const menu = [
  { name: 'การชำระเงินไม่ถูกต้อง', value: 'payment' },
  { name: 'สินค้าซ้ำ', value: 'duplicate' },
  { name: 'สินค้าไม่ตรงตามเงื่อนไข', value: 'condition' },
  { name: 'ล็อกที่จองไม่ว่าง', value: 'unavailable' },
  { name: 'ความสูงเกิน', value: 'height' },
  { name: 'แผนผังมีการเปลี่ยนแปลง', value: 'floor' },
  { name: 'อื่นๆ (โปรดระบุด้านล่าง)', value: 'other' },
]

const PageView = styled.div`
  width: 100%;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`

const Line = styled.div`
  width: 100%;
  padding: 8px 0px;
`

const Note = styled.div`
  width: 100%;
  padding: 8px 0px;
  margin-bottom: 16px;
`

export default Reason
