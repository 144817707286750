import { Modal } from 'antd'

import store from 'stores'

export class Message {
  error(message = 'ขออภัยพบปัญหาบ้างอย่าง') {
    store.message.error({
      message,
    })
  }

  warning({ message }) {
    store.messager.warning({
      message,
    })
  }

  success(message = 'บันทึกสำเร็จ') {
    store.message.success({
      message,
    })
  }

  confirm({ title, item }, onOk = () => {}) {
    Modal.confirm({
      title,
      content: '',
      onOk: () => onOk(item),
      onCancel() {},
    })
  }
}

export const message = new Message()
export default message
