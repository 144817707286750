import styled from 'styled-components'

import Header from './Header'
import Row from './Row'

const TableView = (props) => {
  const { columns, list, store, onLink } = props
  const content = list.map((it, i) => {
    return (
      <Row
        key={i}
        store={store}
        onLink={onLink}
        columns={columns}
        data={it}
      />
    )
  })
  return (
    <PageView>
      <Header columns={columns} />
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

export default TableView
