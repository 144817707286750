import styled from 'styled-components'

import { Row } from 'components/display'
import { MonthPicker } from 'components/input'

import Col from './Col'

const Filter = (props) => {
  const { start, onChange } = props
  return (
    <PageView>
      <Row>
        <Col { ...col }>
          <MonthPicker placeholder="สรุปรายเดือน" value={start} onChange={onChange} />
        </Col>
      </Row>
    </PageView>
  )
}

const col = { lg: 6, md: 12, xs: 24 }

const PageView = styled.div`
  width: 100%;
`

export default Filter
