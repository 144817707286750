import styled from 'styled-components'

import { PrimaryBtn } from 'components/button'

const MarketSave = (props) => {
  const { loading, onClick } = props

  return (
    <PageView>
      <Menu>
        <PrimaryBtn loading={loading} text="บันทึก" onClick={onClick} />
      </Menu>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  position: fixed;
  bottom: 16px;
  left: 0px;
`

const Menu = styled.div`
  width: 100%;
  max-width: 314px;
  margin: 0 auto;
`

export default MarketSave
