import styled from 'styled-components'

import {
  Information,
} from 'components/market/components'

const MarketCreator = (props) => {
  const { data, validate, onChange, onValidate } = props

  return (
    <PageView>
      <Information
        size="xl"
        doc={data}
        validate={validate}
        onChange={onChange}
        onValidate={onValidate} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default MarketCreator
