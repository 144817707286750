import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'
import { Input, InputArea } from 'components/input'
import { LineRow, Tag, Row, Col } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { ImgPicker } from 'components/editor'
import { EditDrawer } from 'drawer'

const MarketAreaInfo = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [postcode, setPostcode] = useState('')

  const { visible, doc = {}, onClose } = props

  useEffect(() => {
    if (visible) {
      doc.postcode_list = doc.postcode_list || []
      setData(doc)
    }
  }, [visible, doc])

  const onAdd = () => {
    data.postcode_list.push({ postcode })
    setData({ ...data })
    setPostcode('')
  }

  const onSave = async () => {
    try {
      setLoading(true)
      await props.marketArea.update(doc)
      onClose()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onChange = (val, name) => {
    data[name] = val
    setData({ ...data })
  }

  const onRemoveTag = (i) => {
    data.postcode_list.splice(i, 1)
    setData({ ...data })
  }

  const onPostcode = (val) => {
    setPostcode(val)
  }

  const postcode_list = data.postcode_list || []

  const content = postcode_list.map((it, i) => {
    return (
      <Tag text={it.postcode} onClick={onRemoveTag} tag={i} />
    )
  })

  return (
    <EditDrawer
      title="ข้อมูลทำเล"
      loading={loading}
      visible={visible}
      onClose={onClose}
      onSave={onSave}
    >
      <LineRow span={12}>
        <Input
          label="ลำดับ"
          name="seq"
          value={data.seq}
          onChange={onChange}
        />
      </LineRow>

      <LineRow span={24}>
        <Input
          label="ชื่อ"
          name="name"
          value={data.name}
          onChange={onChange}
        />
      </LineRow>

      <LineRow span={24}>
        <InputArea
          label="รายละเอียด"
          name="detail"
          value={data.detail}
          onChange={onChange}
        />
      </LineRow>

      <Image>
        <ImgPicker
          value={data.img_url}
          name="img_url"
          onChange={onChange}
        />
      </Image>

      <Label>รายการ รหัสไปรษณีย์</Label>
      <Postcode>
        {content}
      </Postcode>

      <Row align="middle">
        <Col lg={16} md={16} xs={14} position="left">
          <Input
            label="รหัสไปรษณีย์"
            value={postcode}
            onChange={onPostcode}
            isNumber={true}
          />
        </Col>
        <Col lg={8} md={8} xs={10} position="right">
          <PrimaryBtn
            text="เพิ่ม"
            disabled={ postcode.length !== 5}
            onClick={onAdd}
          />
        </Col>
      </Row>
    </EditDrawer>
  )
}

const Image = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-bottom: 16px;
`

const Postcode = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0px 4px;
`

const Label = styled.div`
  color: ${(p) => p.theme.color.blue};
  font-size: 16px;
  margin-bottom: 16px;
`

export default inject('marketArea')(observer(MarketAreaInfo))
