import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { Content, Pagination } from 'components/display'
import Table from 'components/table'
import Loading from 'components/loading'
import { message } from 'utils'
import { Next } from 'icon'
import BookingDrawer from 'pages/booking/page'

import getColumns from './columns'
import Filter from './Filter'

const BookingList = (props) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ status: 'create' })
  const [booking, setBooking] = useState(undefined)

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        props.booking.getList(filter),
        props.booking.getCount(filter)
      ])
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.booking, filter])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onFilter = (val) => setFilter({ ...val })
  const onChange = async ({ index, per_page }) => {
    try {
      setLoading(true)

      await props.booking.getList({ ...filter, index, per_page })
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onView = (item) => setBooking(item)
  const onClose = () => setBooking(undefined)

  const onNext = (item) => {
    return (
      <Btn onClick={() => onView(item)}><Next /></Btn>
    )
  }

  const display = props.booking.toJS().display
  const { page, list } = display
  const columns = getColumns(onNext)

  return (
    <Content title="Booking">
      <Filter filter={filter} onChange={onFilter} />
      <Loading loading={loading}>
        <Table list={list} columns={columns} />
        <Pagination page={page} onChange={onChange} />
      </Loading>
      <BookingDrawer
        visible={booking !== undefined}
        doc={booking}
        onClose={onClose}
        onChange={onView}
      />
    </Content>
  )
}

const Btn = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default inject('booking')(observer(BookingList))
