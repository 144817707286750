import styled from 'styled-components'

const FilePicker = (props) => {
  const {
    name = 'get file',
    readonly = false,
    text = 'Import',
    onSelect = () => {},
    extAccept = '.tsv',
  } = props

  const onClick = () => {
    const input = document.getElementById(name)
    if (input) input.value = null
  }

  const onChange = async (evt) => {
    const files = evt.target.files
    if (files.length === 0) return
    const file = files[0]

    onSelect(file, name)
  }

  return (
    <Section>
      <input
        id={name}
        type="file"
        accept={extAccept}
        style={{ display: 'none' }}
        onChange={onChange}
        onClick={onClick}
      />

      <label readOnly={readonly} htmlFor={readonly ? '' : name}>
        <ActionBtn>
          <span>{text}</span>
        </ActionBtn>
      </label>
    </Section>
  )
}

const Section = styled.div`
  font-size: 12px;
  color: black;
  background-color: white;
  border-radius: 14px;
  border: 1px solid grey;
  padding: 0px 20px;
  width: 100%;
  height: 32px;
  text-align: center;

  cursor: pointer;
  &:hover {
    color: black;
    background-color: white;
    border-color: grey;
  }
`

const ActionBtn = styled.div`
  padding-top: 5px;
  cursor: pointer;
  i {
    margin-right: 5px;
    cursor: pointer;
  }

  span {
    cursor: pointer;
  }
`

export default FilePicker
