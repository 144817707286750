import styled from 'styled-components'

import { Row, Col } from 'components/display'

import Header from './Header'
import Information from './Information'
import Payment from './Payment'

const Booking = (props) => {
  const { doc = {} } = props

  return (
    <PageView>
      <Header doc={doc} />
      <Row>
        <Col lg={12} md={12} xs={24} position="left">
          <Information doc={doc} />
        </Col>
        <Col lg={12} md={12} xs={24} position="right">
          <Payment doc={doc} />
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Booking
