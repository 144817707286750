import styled from 'styled-components'
import { Row, Col } from 'antd'

const RowItem = (props) => {
  const { style, justify, align, children, span, lg, md, xs } = props
  return (
    <PageView style={style}>
      <Row justify={justify} align={align}>
        <Col span={span} lg={lg} md={md} xs={xs}>
          {children}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding-bottom: 16px;
`

export default RowItem
