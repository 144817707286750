import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, helper, error } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  name: '',
}

const name_list = [
  { name: 'Seq', to: 'seq' },
  { name: 'Market Code', to: 'code' },
  { name: 'Market Name', to: 'name' },
  { name: 'Market Type', to: 'market_type' },
  { name: 'Book Type', to: 'booking_type' },
  { name: 'Price', to: 'price' },
  { name: 'Deposit', to: 'deposit' },
  { name: 'Round', to: 'round' },
  { name: 'Open', to: 'open' },
  { name: 'Floor Plan', to: 'floor_plan_url' },
  { name: 'Open Time', to: 'open_time' },
  { name: 'Image', to: 'img_url' },
  { name: 'Address', to: 'address' },
  { name: 'Sub District', to: 'sub_district' },
  { name: 'District', to: 'district' },
  { name: 'Province', to: 'province' },
  { name: 'Postal code', to: 'postcode' },
  { name: 'Map', to: 'map_link' },
  { name: 'Latitude', to: 'lat' },
  { name: 'Longitude', to: 'lng' },
  { name: 'Packing', to: 'parking_status' },
  { name: 'Packing Info', to: 'parking_info' },
  { name: 'Market Area', to: 'market_area' },
  { name: 'Market Type', to: 'market_type' },
  { name: 'Shopper', to: 'shopper' },
  { name: 'Account Number', to: 'account_no' },
  { name: 'Bank', to: 'bank' },
  { name: 'Contact Phone', to: 'contact_phone' },
  { name: 'Contact Name', to: 'contact_name' },
  { name: 'Contact Phone2', to: 'support_contact_phone' },
  { name: 'Contact Name2', to: 'support_contact_name' },
  { name: 'Detail', to: 'detail' },
  { name: 'Nearby', to: 'nearby' },
  { name: 'Equipment', to: 'equipment' },
  { name: 'Interesting', to: 'interesting' },
  { name: 'Sale Condition', to: 'sale_condition' },
]

let state
export class Market extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        list: [],
      },
      doc: cloneDeep(original),
    })
    state = this
  }

  async getList(typ) {
    const url = `${config.api}/v1/admin/market/market-info`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.list = list
    })
  }

  split(text) {
    return `${text || ''}`.split(',').map(it => it.trim()).filter(it => it !== '')
  }

  filterList(list = []) {
    const result_list = []
    let last_item
    for (const item of list) {
      const code = item.code || ''
      const img_url = item.img_url || ''
      const market_area = item.market_area
      const market_type = item.market_type
      const open = item.open
      const booking_type = item.booking_type
      if (code !== '') {
        item.market_area_list = this.split(market_area).map(id => {
          return { market_area_id: +id }
        })

        item.market_type_list = this.split(market_type).map(id => {
          return { market_type_id: +id }
        })

        item.open_list = this.split(open).map(name => {
          return { name }
        })

        item.booking_type_list = this.split(booking_type)

        item.img_list = []
        if (img_url !== '') {
          item.img_list.push(img_url)
        }

        result_list.push(item)
        last_item = item
      } else {
        if (img_url !== '') {
          last_item.img_list.push(img_url)
        }
      }
    }

    return result_list
  }

  async import(lines = []) {
    const content_list = helper.excelListToJson(lines, name_list)
    const list = this.filterList(content_list)

    const url = `${config.api}/v1/admin/market/market-info/import`
    await http.post(url, { json: { list }})
  }

  async remove(id) {
    //const url = `${config.api}/v1/admin/market/market-info/${id}/remove`
    //await http.put(url, { json: { list }})
  }

  async getDoc(id) {
    this.setMarket(cloneDeep(original))

    const url = `${config.api}/v1/admin/market/market-info/${id}`
    const resp = await http.get(url)

    const doc = resp.body || {}

    this.setMarket(doc)
  }

  async createMarket(json = {}) {
    const url = `${config.api}/v1/admin/market/market-info`
    const res = await http.post(url, { json })

    return res.body
  }

  async updateImg({ market_id, image, index }) {
    const url = `${config.api}/v1/admin/market/market-info/${market_id}/image`
    const json = { image, index }

    const res = await http.put(url, { json, check: false })

    const isError = res.status !== 200
    const { img_url, message } = res.body
    return { isError, img_url, index, message }
  }

  async updateFloorplanImg(doc) {
    const { market_id } = doc
    const floor_plan_url = doc.floor_plan_url || ''
    if (floor_plan_url === '' || helper.isUrl(floor_plan_url)) {
      return doc
    }

    const res = await this.updateImg({ market_id, image: floor_plan_url })
    const { isError, img_url, message } = res
    error.isError(isError, message)

    doc.floor_plan_url = img_url
    runInAction(() => {
      state.doc = doc
    })

    return doc
  }

  async updateImgList(doc) {
    const { market_id, img_list } = doc

    const upload_list = []
    let index = 0
    for (const image of img_list) {
      if (helper.isUrl(image) === false) {
        upload_list.push(this.updateImg({ market_id, image, index }))
      }

      index++
    }

    const list = await Promise.all(upload_list)

    let errorMessage = null
    for (const item of list) {
      const { isError, img_url, index, message } = item
      if (isError) {
        errorMessage = message
      } else {
        img_list[index] = img_url
      }
    }

    doc.img_list = img_list
    runInAction(() => {
      state.doc = doc
    })

    error.isError(errorMessage !== null, errorMessage)
    return { ...doc }
  }

  async updateInfo(doc) {
    let json = await this.updateFloorplanImg(doc)
    json = await this.updateImgList(json)

    const url = `${config.api}/v1/admin/market/market-info/${doc.market_id}/information`
    delete json.detail
    const res = await http.put(url, { json })

    const data = res.body
    runInAction(() => {
      state.doc = {
        ...doc,
        ...data,
      }
    })
  }

  async updateBank(doc) {
    const url = `${config.api}/v1/admin/market/market-info/${doc.market_id}/banking`
    const json = { ...doc }

    delete json.img_list
    delete json.detail
    await http.put(url, { json })
  }

  async updateOther(doc) {
    const url = `${config.api}/v1/admin/market/market-info/${doc.market_id}/other`
    const json = { ...doc }

    delete json.img_list
    await http.put(url, { json })
  }

  async updateStatus(market_id, status) {
    const url = `${config.api}/v1/admin/market/market-info/${market_id}/status/${status}`
    await http.put(url)

    const list = this.toJS().display.list
    const i = list.findIndex(it => it.market_id === market_id)
    if (i !== -1) {
      list[i].status = status
    }

    runInAction(() => {
      state.display.list = list
    })
  }

  setMarket(doc) {
    runInAction(() => {
      state.doc = doc
    })
  }
}

export default new Market()
