import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row, Col } from 'components/display'
import { PrimaryBtn } from 'components/button'
import { format } from 'utils'

const UserItem = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState(undefined)
  const { market_id, user = {} } = props
  const { name, surname, mobile } = user

  const onClick = async () => {
    try {
      setLoading(true)
      setErr(undefined)
      await props.marketMember.addMember(market_id, user)
    } catch (e) {
      setErr(e.message)
    }
    setLoading(false)
  }

  const fullname = `${name} ${surname}`
  const msg = err ? <Error>{err}</Error> : undefined
  return (
    <>
      <Row align="middle">
        <Col lg={12} md={12} xs={24}>
          {fullname}
        </Col>

        <Col lg={8} md={8} xs={24}>
          {format.toPattern(mobile, 'xxx-xxx-xxxx')}
        </Col>

        <Col lg={4} md={4} xs={24}>
          <PrimaryBtn
            loading={loading}
            text="เพิ่ม"
            onClick={onClick}
          />
        </Col>
      </Row>
      {msg}
    </>
  )
}

const Error = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.color.error};
`

export default inject('marketMember')(observer(UserItem))
