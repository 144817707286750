import styled from 'styled-components'

import { format } from 'utils'

const Card = (props) => {
  const { label, value, total, color } = props

  const content = total !== undefined ? (
    <Total className={color}>{format.toDigi(value)} <span>/ {format.toDigi(total)}</span></Total>
  ) : <Total className={color}>{format.toDigi(value)}</Total>

  return (
    <PageView>
      <Label>{label}</Label>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 8px 18px;
  background-color: white;
  border-radius: 10px;
  ${(p) => p.theme.media.mobile} {
    margin-bottom: 8px;
  }
`

const Label = styled.div`
  width: 100%;
  font-size: 14px;
`

const Total = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #6ebe9f;

  span {
    color: #026342;
  }

  &.red {
    color: #c73558;
  }
`

export default Card
