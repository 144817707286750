import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  content: '',
}

const name_list = [
  { name: 'Seq', to: 'seq' },
  { name: 'Area Code', to: 'code' },
  { name: 'Name', to: 'name' },
  { name: 'Detail', to: 'detail' },
  { name: 'Image', to: 'img_url' },
]

let state
export class MarketArea extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        list: [],
      },
      doc: cloneDeep(original),
    })
    state = this
  }

  async getList(typ) {
    const url = `${config.api}/v1/admin/market/market-area`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.list = list
    })
  }

  async import(lines = []) {
    const list = helper.excelListToJson(lines, name_list, { isFilter: true })
    const url = `${config.api}/v1/admin/market/market-area/import`
    await http.post(url, { json: { list }})
  }

  async remove(id) {
    const url = `${config.api}/v1/admin/market/market-area/${id}/remove`
    await http.put(url)
  }

  async update(json = {}) {
    const list = this.toJS().display.list

    const { market_area_id } = json
    if (market_area_id) {
      const url = `${config.api}/v1/admin/market/market-area/${market_area_id}`
      const res = await http.put(url, { json })
      const doc = res.body
      const i = list.findIndex(it => it.market_area_id === market_area_id)
      if (i !== -1) {
        list[i] = doc
      }
    } else {
      const url = `${config.api}/v1/admin/market/market-area`
      const res = await http.post(url, { json })
      const doc = res.body
      list.push(doc)
    }

    runInAction(() => {
      state.display.list = list
    })
  }
}

export default new MarketArea()
