import styled from 'styled-components'

import { Card, Dot } from 'components/display'

import Contact from './Contact'
import Note from './Note'
import Invoice from './Invoice'
import PaymentInfo from './PaymentInfo'

const Payment = (props) => {
  const { doc = {} } = props

  return (
    <PageView>
      <Card>
        <Contact order={doc} />
        <Dot />
        <Note order={doc} />
        <Dot />
        <Invoice order={doc} />
        <Dot />
        <PaymentInfo order={doc} />
      </Card>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Payment
