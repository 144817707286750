import { DropdownList, DatePicker } from 'components/input'
import { Row, Col } from 'components/display'

const BookingFilter = (props) => {
  const { filter = {}, onChange } = props

  const onCh = (val, name) => {
    filter[name] = val
    onChange(filter)
  }

  return (
    <Row>
      <Col lg={5} md={8} xs={24} position="left">
        <DropdownList
          placeholder="สถานะตลาด"
          name="status"
          value={filter.status}
          menu={menu_list}
          onChange={onCh}
          clear={false}
        />
      </Col>
      <Col lg={4} md={6} xs={24} position="right">
        <DatePicker
          placeholder="วันที่จอง"
          name="date"
          value={filter.date}
          onChange={onCh}
        />
      </Col>
    </Row>
  )
}

const menu_list = [
  { name: 'รอการยืนยัน', value: 'create' },
  { name: 'รอชำระเงิน', value: 'payment' },
  { name: 'เสนอใหม่', value: 'offer' },
  { name: 'การจองสำเร็จ', value: 'completed' },
  { name: 'การจองถูกยกเลิก', value: 'cancelled' },
]

export default BookingFilter
