import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Shop extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        list: [],
      },
    })
    state = this
  }

  async getList() {
    const url = `${config.api}/v1/admin/market/shop`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.list = list
    })
  }
}

export default new Shop()
