import axios from 'axios'

let token
let member

const isRespError = (resp, params = {}) => {
  let { message } = params
  if (resp.status === 200) {
    return false
  } else if (resp.status === 401) {
    resp.body = {}
    message = 'กรุณาเข้าสู่ระบบใหม่'

    if (member) member.reset()
  } else if (resp.status === 404) {
    resp.body = {}
    message = 'ระบบไม่พร้อมใช้งาน'
  }

  const msg = message ? message : resp.body.message

  throw Error(msg)
}

export const setToken = (auth) => {
  token = auth
}

export const getToken = () => {
  return token
}

export const setMember = (val) => {
  member = val
}

export const process = async (options) => {
  try {
    const resp = await axios(options)
    const { status, data } = resp
    return {
      status,
      body: data,
    }
  } catch (e) {
    return {
      status: 500,
      body: { message: e.message }
    }
  }
}

export const setOption = (setting, options = {}) => {
  const { authorization = true } = options

  setting.headers = {
    'Content-Type': 'application/json',
  }

  if (authorization) {
    setting.headers['authorization'] = `Bearer ${token}`
  }

  return setting
}

export const callApi = async (method, url, options = {}) => {
  let setting = {
    method,
    url,
    data: options.json || {},
    validateStatus: (status) => {
      return status >= 200
    },
  }

  setting = setOption(setting, options)
  const resp = await process(setting)

  const { check = true } = options
  if (check) isRespError(resp)

  return resp
}

export const get = async (url, options = {}) => {
  return await callApi('get', url, options)
}

export const post = async (url, options = {}) => {
  return await callApi('post', url, options)
}

export const put = async (url, options = {}) => {
  return await callApi('put', url, options)
}

const http = {
  get,
  put,
  post,
  setMember,
  setToken,
}
export default http
