import styled from 'styled-components'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'

import { BaseDrawer } from 'drawer'
import { PrimaryBtn } from 'components/button'
import { message } from 'utils'

import Booking from './Booking'
import Reason from './Reason'

const BookingDrawer = (props) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('')
  const { visible, doc = {}, onClose, onChange } = props

  const { order_id, status } = doc

  const onDisplayReject = () => setPage('reject')
  const onCloseReject = () => setPage('')

  const onConfirm = async (text) => {
    try {
      setLoading(true)

      const data = await props.booking.cancel(order_id, text)
      if (data) onChange(data)
      onCloseReject()
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const btn = page === '' && status !== 'cancelled' ? (
    <Btn>
      <PrimaryBtn text="ยกเลิกการจอง" onClick={onDisplayReject} />
    </Btn>
  ) : undefined

  const content = page === '' ? <Booking doc={doc} /> : <Reason loading={loading} onConfirm={onConfirm} onClose={onCloseReject} />

  return (
    <BaseDrawer title="รายการจอง" visible={visible} width={'800px'} onClose={onClose}>
      <PageView>
        {content}
        {btn}
      </PageView>
    </BaseDrawer>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 0px 16px;
`

const Btn = styled.div`
  width: 214px;
  margin: 0 auto;
`

export default inject('booking')(observer(BookingDrawer))
