import styled from 'styled-components'

import { format } from 'utils'


const onCreatedAt = (item) => {
  return `${format.toDatetime(item.created_at)}`
}

const onImage = (item) => {
  const img_list = item.img_list || []

  return img_list.length === 0 ? '' : <Image src={img_list[0]} />
}

const columns = [
  {
    title: 'รูปสินค้า',
    render: onImage,
    size: {
      lg: 4, md: 6, xs: 12
    }
  },
  {
    title: 'ชื่อสินค้า',
    key: 'name',
    size: {
      lg: 7, md: 12, xs: 24
    }
  },
  {
    title: 'ชื่อร้านค้า',
    key: 'shop_name',
    size: {
      lg: 7, md: 12, xs: 24
    }
  },
  {
    title: 'วันที่สร้าง',
    render: onCreatedAt,
    size: {
      lg: 6, md: 6, xs: 12
    }
  },
]

const Image = styled.img`
  width: 60px;
  border-radius: 8px;
`

export default columns
