import styled from 'styled-components'

import Title from '../Title'

const NoteSection = (props) => {
  const { order = {} } = props

  const { note = '' } = order

  return (
    <PageView>
      <Title text="ข้อความถึงเจ้าหน้าที่" />
      <Text>{note !== '' ? note : 'ไม่ระบุ'}</Text>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 16px 0px 10px 0px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
`

export default NoteSection
