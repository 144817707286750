import styled from 'styled-components'
import { Button } from 'antd'

const Btn = (props) => {
  const { disabled, children, text, invalid, loading } = props
  const { name, onClick = () => {} } = props

  const onEvt = () => {
    onClick(name)
  }

  let click
  if (!loading && !disabled) {
    click = onEvt
  }

  const err = invalid ? <ErrorText>{invalid}</ErrorText> : undefined
  return (
    <PageView>
      <Button
        disabled={disabled}
        loading={loading}
        onClick={click}
      >
        {children || text}
      </Button>
      {err}
    </PageView>
  )
}

const PageView = styled.div`
  button, button:hover, button:focus {
    color: white;
    background-color: ${(p) => p.theme.color.blue};
    width: 100%;
    height: 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 0px;
  }

  button:disabled {
    color: white;
    background-color: ${(p) => p.theme.color.disable} !important;
  }
`

const ErrorText = styled.div`
  font-size: 14px;
  padding-top: 8px;
  color: ${(p) => p.theme.color.error};
`

export default Btn
