
import { market } from 'utils'
import { OpenTag } from 'components/display'
import { Switch } from 'components/input'

const onContact = (item) => {
  return `${item.contact_name} ${item.contact_phone}`
}

const onOpenMarket = (item) => {
  return <OpenTag item={item} />
}

const onOpenTimeMarket = (item) => {
  return market.toOpenTime(item)
}

const onStatus = (item, index, store) => {
  return (
    <Switch
      value={item.status === 'active'}
      onChange={store.onStatus}
      tag={item}
    />
  )
}

const columns = [
  {
    title: 'Seq',
    key: 'seq',
    size: {
      lg: 2, md: 3, xs: 3
    }
  },
  {
    title: 'ชื่อ',
    key: 'name',
    size: {
      lg: 6, md: 12, xs: 21
    }
  },
  {
    title: 'ราคาเริ่มต้น',
    key: 'price',
    size: {
      lg: 2, md: 3, xs: 4
    }
  },
  {
    title: 'เวลาเปิด',
    render: onOpenTimeMarket,
    size: {
      lg: 4, md: 6, xs: 6
    }
  },
  {
    title: 'วันที่เปิด',
    render: onOpenMarket,
    size: {
      lg: 3, md: 6, xs: 6
    }
  },
  {
    title: 'ติดต่อ',
    render: onContact,
    size: {
      lg: 5, md: 4, xs: 6
    }
  },
  {
    title: 'เปิด/ปิด',
    render: onStatus,
    isLink: false,
    size: {
      lg: 2, md: 4, xs: 6
    }
  },
]

export default columns
