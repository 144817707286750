import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Row } from 'components/display'

import Col from './Col'
import Card from './Card'

const Counter = (props) => {
  const { counter } = props.dashboard.toJS()
  return (
    <PageView>
      <Row>
        <Col { ...col }>
          <Card label="จำนวนผู้ใช้งาน" value={counter.new_user} total={counter.total_user} />
        </Col>
        <Col { ...col }>
          <Card label="จำนวนร้านค้า" value={counter.new_shop} total={counter.total_shop} />
        </Col>
        <Col { ...col }>
          <Card label="จำนวนตลาด" value={counter.new_market} total={counter.total_market} />
        </Col>
      </Row>
    </PageView>
  )
}

const col = { lg: 6, md: 12, xs: 24 }

const PageView = styled.div`
  width: 100%;
`

export default inject('dashboard')(observer(Counter))
