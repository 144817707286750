import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { message, helper } from 'utils'
import { Content, Image } from 'components/display'
import Table from 'components/table'
import { FilePicker } from 'components/button'
import { RemoveBtn } from 'components/circle'
import Loading from 'components/loading'
import { ConfirmDialog } from 'dialog'

const MarketTypeList = (props) => {
  const [loading, setLoading] = useState(false)
  const [removal, setRemoval] = useState(null)

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await props.marketType.getList()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.marketType])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const importContent = async (lines, err) => {
    if (!lines) {
      message.error(err)
      return
    }
    try {
      setLoading(true)
      await props.marketType.import(lines)
      await props.marketType.getList()

      message.success('บันทึกสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onImportFile = (file) => {
    helper.loadFile(file, importContent)
  }

  const onRemove = async () => {
    try {
      setLoading(true)
      setRemoval(null)
      await props.marketType.remove(removal.market_type_id)
      await props.marketType.getList()

      message.success('ลบสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const confirmRemove = (item) => setRemoval(item)
  const onClose = () => setRemoval(null)

  const onRender = (item, i) => {
    return (
      <RemoveBtn tag={item} onClick={confirmRemove} />
    )
  }

  const onImg = (item, i) => {
    return (
      <Image img_url={item.img_url} />
    )
  }

  const { list } = props.marketType.toJS().display

  const columns = [
    {
      title: 'Id',
      key: 'market_type_id',
      size: {
        lg: 2, md: 2, xs: 4
      }
    },
    {
      title: 'Seq',
      key: 'seq',
      size: {
        lg: 2, md: 4, xs: 12
      }
    },
    {
      title: 'Code',
      key: 'code',
      size: {
        lg: 3, md: 4, xs: 12
      }
    },
    {
      title: 'ชื่อ',
      key: 'name',
      size: {
        lg: 5, md: 4, xs: 12
      }
    },
    {
      title: 'รายละเอียด',
      key: 'detail',
      size: {
        lg: 8, md: 4, xs: 12
      }
    },
    {
      title: 'รูป',
      render: onImg,
      size: {
        lg: 2, md: 4, xs: 12
      }
    },
    {
      title: '',
      render: onRender,
      align: 'right',
      size: {
        lg: 2, md: 4, xs: 12
      }
    }
  ]

  const btn = (
      <FilePicker
      loading={loading}
      text="นำเข้า"
      onSelect={onImportFile}
      extAccept=".xlsx"
    />
  )
  return (
    <Content title="Market Type" right={btn}>
      <Loading loading={loading}>
        <Table list={list} columns={columns} />
      </Loading>
      <ConfirmDialog
        title="ยืนยันการลบ ใช่หรือไม่?"
        visible={removal !== null}
        onClose={onClose}
        onConfirm={onRemove}
      />
    </Content>
  )
}

export default inject('marketType')(observer(MarketTypeList))
