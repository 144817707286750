import styled from 'styled-components'

import Icon from './Icon'
import LoadingIcon from './LoadingIcon'

const BaseBtn = (props) => {
  const {
    text = '',
    loading = false,
    disabled,
    icon,
    color,
    bg_color,
    border_color,
    onClick,
  } = props

  let click
  let css
  if (!disabled) {
    click = onClick
    css = 'click'
  }

  const icon_content =
    loading && !disabled ? (
      <LoadingIcon loading={true} />
    ) : icon ? (
      <Icon img={icon} />
    ) : undefined

  return (
    <PageView
      onClick={click}
      className={css}
      color={color}
      bg_color={bg_color}
      border_color={border_color}
    >
      {icon_content}

      <label>{text}</label>
    </PageView>
  )
}

const PageView = styled.div`
  border-radius: 5px;
  padding: 6px 8px;
  width: 100%;
  height: 40px;
  font-size: 16px;

  color: ${(props) => props.color};
  background-color: ${(props) => props.bg_color};
  border: 1px solid ${(props) => props.border_color};

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  &.click,
  &.click > label {
    cursor: pointer;
  }

`

export default BaseBtn
