import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message, helper } from 'utils'
import { Content, Image } from 'components/display'
import Table from 'components/table'
import { FilePicker } from 'components/button'
import { EditBtn, RemoveBtn } from 'components/circle'
import Loading from 'components/loading'
import { ConfirmDialog } from 'dialog'

import InformationDialog from './Information'

const MarketAreaList = (props) => {
  const [loading, setLoading] = useState(false)
  const [removal, setRemoval] = useState(undefined)
  const [editor, setEditor] = useState(undefined)

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await props.marketArea.getList()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.marketArea])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const importContent = async (lines, err) => {
    if (!lines) {
      message.error(err)
      return
    }
    try {
      setLoading(true)
      await props.marketArea.import(lines)
      await props.marketArea.getList()

      message.success('บันทึกสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onImportFile = (file) => {
    helper.loadFile(file, importContent)
  }

  const onRemove = async () => {
    try {
      setLoading(true)
      setRemoval(null)
      await props.marketArea.remove(removal.market_area_id)
      await props.marketArea.getList()

      message.success('ลบสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const confirmRemove = (item) => setRemoval(item)
  const onClose = () => setRemoval(undefined)
  const onEdit = (item) => setEditor(item)
  const onEditClose = () => setEditor(undefined)

  const onRender = (item, i) => {
    return (
      <Button>
        <EditBtn
          tag={item}
          onClick={onEdit}
        />
        <RemoveBtn
          tag={item}
          onClick={confirmRemove}
        />
      </Button>
    )
  }

  const onPostcode = (item, i) => {
    const postcode_list = item.postcode_list || []
    const text = postcode_list.map(it => it.postcode).join(', ')
    return text
  }

  const onImg = (item, i) => {
    return (
      <Image img_url={item.img_url} />
    )
  }

  const { list } = props.marketArea.toJS().display

  const columns = [
    {
      title: 'ลำดับ',
      key: 'seq',
      size: {
        lg: 2, md: 4, xs: 12
      }
    },
    {
      title: 'Code',
      key: 'code',
      size: {
        lg: 3, md: 4, xs: 12
      }
    },
    {
      title: 'ชื่อ',
      key: 'name',
      size: {
        lg: 5, md: 4, xs: 12
      }
    },
    {
      title: 'รายละเอียด',
      key: 'detail',
      size: {
        lg: 6, md: 6, xs: 12
      }
    },
    {
      title: 'รหัสไปรษณีย์',
      render: onPostcode,
      size: {
        lg: 4, md: 6, xs: 12
      }
    },
    {
      title: 'รูป',
      render: onImg,
      size: {
        lg: 2, md: 3, xs: 12
      }
    },
    {
      title: '',
      render: onRender,
      align: 'right',
      size: {
        lg: 2, md: 4, xs: 12
      }
    }
  ]

  const btn = (
      <FilePicker
      loading={loading}
      text="นำเข้า"
      onSelect={onImportFile}
      extAccept=".xlsx"
    />
  )
  return (
    <Content title="Market Area" right={btn}>
      <Loading loading={loading}>
        <Table list={list} columns={columns} />
      </Loading>
      <ConfirmDialog
        title="ยืนยันการลบ ใช่หรือไม่?"
        visible={removal !== undefined}
        onClose={onClose}
        onConfirm={onRemove}
      />
      <InformationDialog
        visible={editor !== undefined}
        doc={editor}
        onClose={onEditClose}
      />
    </Content>
  )
}

const Button = styled.div`
  display: flex;
  column-gap: 8px;
`

export default inject('marketArea')(observer(MarketAreaList))
