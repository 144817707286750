import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { Content, Pagination } from 'components/display'
import Table from 'components/table'
import Loading from 'components/loading'
import { message } from 'utils'

import getColumns from './columns'

const UserList = (props) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({})

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      await Promise.all([
        props.user.getList(filter),
        props.user.getCount(filter)
      ])
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.user, filter])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onFilter = (val) => setFilter({ ...val })
  const onChange = async ({ index, per_page }) => {
    try {
      setLoading(true)

      await props.user.getList({ ...filter, index, per_page })
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const display = props.user.toJS().display
  const { page, list } = display
  const columns = getColumns()

  return (
    <Content title="User">
      <Loading loading={loading}>
        <Table list={list} columns={columns} />
        <Pagination page={page} onChange={onChange} />
      </Loading>
    </Content>
  )
}

export default inject('user')(observer(UserList))
