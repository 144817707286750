import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  content: '',
}

const name_list = [
  { name: 'Seq', to: 'seq' },
  { name: 'Type Code', to: 'code' },
  { name: 'Name', to: 'name' },
  { name: 'Detail', to: 'detail' },
  { name: 'Image', to: 'img_url' },
]

let state
export class MarketType extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        list: [],
      },
      market_type_list: [],
      doc: cloneDeep(original),
    })
    state = this
  }

  async getList(force = true) {
    const current_list = this.toJS().display.list
    if (force === false && current_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/admin/market/market-type`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.list = list
      state.market_type_list = list
    })
  }

  async import(lines = []) {
    const list = helper.excelListToJson(lines, name_list, { isFilter: true })
    const url = `${config.api}/v1/admin/market/market-type/import`
    await http.post(url, { json: { list }})
  }

  async remove(id) {
    const url = `${config.api}/v1/admin/market/market-type/${id}/remove`
    await http.put(url)
  }
}

export default new MarketType()
