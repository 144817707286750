import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Chart from 'components/chart'
import config from 'components/chart/config'

const { borderWidth, color } = config

const color_list = [
  color.Blue,
  color.Yellow,
  color.Brown,
  color.Grey,
  color.Green,
]

const Information = (props) => {
  const { information } = props.dashboard.toJS()
  const { code, label_list, counter_list } = information

  const datasets = counter_list.map((it, i) => {
    const { label, data_list } = it

    return {
      label,
      data: data_list,
      backgroundColor: color_list[i],
      borderColor: color_list[i],
      borderWidth,
      fill: false,
    }
  })

  const data = {
    labels: label_list,
    datasets,
  }

  const chart = {
    type: 'line', // line
    data,
    options: {
      responsive: true,
      scales: {
        y: { beginAtZero: true },
      },
    },
  }

  return (
    <PageView>
      <Chart id="dashboard" code={code} data={chart} />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
`

export default inject('dashboard')(observer(Information))
