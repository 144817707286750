import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Dot } from 'components/display'
import { format, market, product } from 'utils'

const Product = (props) => {
  const { item = {}, isDot } = props
  const { start_at, finish_at, deposit_type, total_price, promotion_price = 0, deposit, promotion } = item

  const price = promotion_price === 0 ? total_price : promotion_price

  const getText = (label, text) => {
    return (
      <Text>
        <span>{label}</span>
        {text}
      </Text>
    )
  }

  const getDeposit = () => {
    if (deposit_type !== 'yes') return

    return (
      <>
        {getText('ค่าเช่า:', `${format.toDigi(price)} บาท`)}
        {getText('มัดจำ/ประกัน:', `${format.toDigi(deposit)} บาท`)}
      </>
    )
  }

  const getPromotion = () => {
    if (!promotion) return

    const text = product.getTextPromotion(promotion)
    return getText('โปรโมชั่น:', text)
  }

  const bookingType = market.getBookingTypeName(item.booking_type)
  const date = product.getDate(start_at, finish_at)
  const dot = isDot ? <Dot /> : undefined
  const total = deposit_type === 'yes' ? price + deposit : price
  return (
    <PageView>
      <Body>
        <Row>
          <Col span={16}>
            <NameLine>
              <Name>{item.name}</Name>
              <Round>{bookingType}</Round>
            </NameLine>
          </Col>

          <Col span={8}>
            <Price>
              {format.toDigi(total)}
              <span>บาท</span>
            </Price>
          </Col>
        </Row>

        <Info>
          {getText('วันที่ขาย:', date)}
          {getText('รอบ:', item.round)}
          {getDeposit()}
          {getPromotion()}
          {getText('โซน:', item.detail)}
        </Info>
      </Body>

      {dot}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

const NameLine = styled.div`
  margin-bottom: 8px;
  display: flex;
  column-gap: 8px;
`

const Round = styled.div`
  font-size: 14px;
  color: white;
  background-color: #f55759;
  border-radius: 4px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Price = styled.div`
  color: ${(p) => p.theme.color.red};
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  text-align: right;

  span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 6px;
  }
`

const Body = styled.div`
  padding: 20px 0px;
`

const Name = styled.div`
  font-size: 16px;
  width: fit-content;
  color: white;
  background-color: ${(p) => p.theme.color.green};
  border-radius: 4px;
  font-weight: bold;
  padding: 0px 8px;
`

const Info = styled.div`
  width: 100%;
`

const Text = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color_level.grey.high};

  span {
    font-weight: bold;
    margin-right: 8px;
  }
`

export default Product
