import styled from 'styled-components'
import { Col } from 'antd'

const ColItem = (props) => {
  const { children } = props
  return (
    <Col {...props}>
      <PageView>{children}</PageView>
    </Col>
  )
}

const PageView = styled.div`
  padding-right: 12px;
`

export default ColItem
