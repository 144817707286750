import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

import { Close } from 'icon'

const BaseDialog = (props) => {
  const { visible, width, onClose, children } = props

  const onClickInside = (e) => {
    e.stopPropagation()
  }

  const closeBtn = onClose ? (
    <TopMenu>
      <CloseMenu onClick={onClose}>
        <Close />
      </CloseMenu>
    </TopMenu>
  ) : undefined

  return (
    <Modal
      title=""
      footer=""
      visible={visible}
      className="editor-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <PageView onClick={onClose}>
        <PageArea>
          <PageBody style={{ width }} onClick={onClickInside}>
            {closeBtn}
            <BodyContent>{children}</BodyContent>
          </PageBody>
        </PageArea>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(20, 20, 43, 0.5);
`

const PageArea = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
`

const PageBody = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 20px;
`

const BodyContent = styled.div`
  min-width: 200px;
`

const TopMenu = styled.div`
  width: 100%;
  text-align: right;
  padding-bottom: 5px;
`

const CloseMenu = styled.span`
  cursor: pointer;
`

export default BaseDialog
