import member from './Member'
import menu from './Menu'
import consent from './Consent'
import message from './Message'
import market from './Market'
import marketType from './MarketType'
import marketArea from './MarketArea'
import marketMember from './MarketMember'
import marketSetting from './MarketSetting'
import province from './Province'
import shop from './Shop'
import booking from './Booking'
import dashboard from './Dashboard'
import user from './User'

/* eslint-disable */
export default {
  member,
  menu,
  consent,
  message,
  market,
  marketType,
  marketArea,
  marketMember,
  marketSetting,
  province,
  shop,
  booking,
  dashboard,
  user,
}
// eslint-enable
