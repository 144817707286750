import styled from 'styled-components'
import { Row } from 'antd'

import { Col } from 'components/display'
import { format } from 'utils'

import Title from '../Title'

const Invoice = (props) => {
  const { order = {} } = props
  const getText = (label, text) => {
    return (
      <Row>
        <Col span={12}>
          <Label>{label}</Label>
        </Col>
        <Col span={12}>
          <Text>{text}</Text>
        </Col>
      </Row>
    )
  }

  const {
    tax_required,
    tax_id,
    tax_name,
    branch_code,
    company_mobile,
    tax_address,
    receive_type,
    receive_name,
    receive_mobile,
    receive_address,
  } = order

  if (!tax_required) {
    return (
      <PageView>
        <Title text="ข้อมูลออกใบกำกับภาษี" />
        <Detail>ไม่ออกใบกำกับภาษี</Detail>
      </PageView>
    )
  }

  const isMain = branch_code === '' || branch_code === '0'
  const branch = isMain ? 'สำนักงานใหญ่' : branch_code

  const isAddress = receive_type === 'address'
  const invoiceType = isAddress ? 'จัดส่งทางไปรษณีย์' : 'รับที่ตลาด'
  const address = isAddress ? (
    <Detail>
      <div>{receive_name}</div>
      <div>โทร. {format.toMobile(receive_mobile)}</div>
      <div>{receive_address}</div>
    </Detail>
  ) : undefined

  return (
    <PageView>
      <Title text="ข้อมูลออกใบกำกับภาษี" />
      {getText(
        'เลขบัตรประจำตัวผู้เสียภาษีอากร / เลขบัตรประชาชน:',
        format.toPattern(tax_id, 'x-xxxx-xxxxx-xx-x')
      )}
      {getText('ชื่อบริษัท / ชื่อบุคคล:', tax_name)}
      {getText('สาขา:', branch)}
      {getText('เบอร์โทรศัพท์:', format.toMobile(company_mobile))}
      <Label>ที่อยู่ในการออกใบกำกับภาษี</Label>
      <Detail>{tax_address}</Detail>
      <Title text="วิธีรับใบกำกับภาษี" />
      <Detail>{invoiceType}</Detail>
      {address}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-top: 16px;
`

const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`

const Text = styled.div`
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  font-weight: normal;
`

const Detail = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
`

export default Invoice
