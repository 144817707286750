import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Content, Row } from 'components/display'
import { message, timer } from 'utils'

import Filter from './Filter'
import Col from './Col'
import Counter from './Counter'
import SummaryBooking from './SummaryBooking'
import ChartCounter from './ChartCounter'
import ChartBooking from './ChartBooking'
import PendingBooking from './PendingBooking'

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false)
  const [start, setStart] = useState(timer.get())

  const onGetList = useCallback(async (start_at) => {
    try {
      setLoading(true)
      const [marketData, userData] = await Promise.all([
        props.dashboard.getMarketCounter({ start_at }),
        props.dashboard.getUserCounter({ start_at }),
      ])

      props.dashboard.setDashboard({ start_at }, marketData, userData)
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.dashboard])

  useEffect(() => {
    onGetList(start)
  }, [onGetList, start])

  const onChange = (val) => setStart(val)

  return (
    <Content title="Dashboard">
      <Filter start={start} onChange={onChange} />
      <Counter />
      <SummaryBooking />
      <Row>
        <Col lg={16} md={24} xs={24}>
          <ChartBooking />
          <Line />
          <ChartCounter />
        </Col>
        <Col lg={8} md={24} xs={24}>
          <PendingBooking />
        </Col>
      </Row>
    </Content>
  )
}

const Line = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export default inject('dashboard')(observer(Dashboard))
