import BaseBtn from './BaseBtn'

const GoogleBtn = (props) => {
  const { disabled, onClick, text, loading } = props

  return (
    <BaseBtn
      text={text}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      color={'white'}
      bg_color={'#D83E29'}
      border_color={'#D83E29'}
    />
  )
}

export default GoogleBtn
