import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { checkGoogle } from 'app/firebaseApp'
import { error, firebase, http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  uid: '',
  provider: {},
  img_url: undefined,
  email: '',
  name: '',
  surname: '',
  role: '',
}

let state
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      verifying: false,
      user: cloneDeep(original),
    })

    state = this
    http.setMember(this)
  }

  reset() {
    firebase.auth().signOut()

    runInAction(() => {
      state.verifying = false
      state.user = cloneDeep(original)
      state.firebase = {}
    })
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged((user) => {
        resolve(user)
      })
    })
  }

  async checkLoginUser() {
    runInAction(() => {
      state.verifying = true
    })

    try {
      const user = await this.getCurrentUser()

      if (user) {
        runInAction(() => {
          state.firebase = user
        })
        await this.getUserProfile({ user })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }

    runInAction(() => {
      state.verifying = false
    })
  }

  getProvider({ user }) {
    const list = user.providerData || []
    return list.map((item) => item.providerId)
  }

  setUserProfile({ user }) {
    if (user) {
      runInAction(() => {
        state.user = user
      })
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    await http.setToken(token)

    const url = `${config.api}/v1/admin/profile`
    const resp = await http.get(url)

    const data = resp.body
    await this.setUserProfile({ user: data })
  }

  isLogin() {
    return this.user.uid !== ''
  }

  async logout() {
    await http.setToken()
    await this.reset()
  }

  async loginByGoogle() {
    const result = await checkGoogle()

    const { user } = result
    error.isNull(user, { message: 'user not found' })
    return await this.getUserProfile({ user })
  }
}

export default new Member()
