import styled from 'styled-components'

import { Card, Dot } from 'components/display'

import MarketName from './MarketName'
import Product from './Product'
import Total from './Total'

const Information = (props) => {
  const { doc = {} } = props
  const { status, market_name, total } = doc

  const list = doc.list || []
  const last = list.length - 1
  const content = list.map((it, i) => {
    return <Product key={i} item={it} isDot={i !== last} />
  })

  return (
    <PageView>
      <Card>
        <MarketName market_name={market_name} status={status} />
        {content}
        <Dot />
        <Total total={total} />
      </Card>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Information
