import React, { useState, useEffect, useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import { Routes, Route } from 'react-router-dom'

import Loading from 'components/loading'
import Dashboard from 'pages/dashboard'
import Consent from 'pages/consent'
import LoginPage from 'pages/login'
import MerchantList from 'pages/merchant/list'
import BookingList from 'pages/booking/list'
import MarketList from 'pages/market/list'
import MarketInfo from 'pages/market/information'
import MarketOther from 'pages/market/other'
import MarketBanking from 'pages/market/banking'
import MarketSetting from 'pages/market/setting'
import MarketMember from 'pages/market/member'
import UserList from 'pages/user/list'

import MarketTypeList from 'pages/setting/marketType'
import MarketAreaList from 'pages/setting/marketArea'
import ProvinceList from 'pages/setting/province'
import MainLayout from 'menu'

const App = (props) => {
  const [loading, setLoading] = useState(false)

  const { history } = props
  const isLogin = props.member.isLogin()

  const onCheck = useCallback(async () => {
    if (isLogin) return
    try {
      setLoading(true)
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member, isLogin])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="*" element={<Loading />} />
      </Routes>
    )
  } else if (isLogin === false) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    )
  }

  return (
    <MainLayout history={history}>
      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/consent/:typ" element={<Consent />} />
        <Route path="/booking" element={<BookingList />} />
        <Route path="/merchant" element={<MerchantList />} />
        <Route path="/market" element={<MarketList />} />
        <Route path="/market/:market_id" element={<MarketInfo />} />
        <Route path="/market/:market_id/other" element={<MarketOther />} />
        <Route path="/market/:market_id/bank" element={<MarketBanking />} />
        <Route path="/market/:market_id/member" element={<MarketMember />} />
        <Route path="/market/:market_id/setting" element={<MarketSetting />} />

        <Route path="/user" element={<UserList />} />

        <Route path="/market-type" element={<MarketTypeList />} />
        <Route path="/market-area" element={<MarketAreaList />} />
        <Route path="/province" element={<ProvinceList />} />
      </Routes>
    </MainLayout>
  )
}

export default inject('member')(observer(App))
