import styled from 'styled-components'

const MarketName = (props) => {
  const { market_name } = props

  return (
    <PageView>
      <Name>{market_name}</Name>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  width: 100%;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${(p) => p.theme.color.blue};
`

export default MarketName
