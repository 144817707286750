import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

const TableView = (props) => {
  const { columns = [] } = props
  const list = columns.map((it, i) => {
    return (
      <Col key={i} {...it.size} >
        {it.title}
      </Col>
    )
  })
  return (
    <PageView>
      <Row>
        {list}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 0px 20px;
`

export default TableView
