import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class MarketMember extends BaseStore {
  constructor() {
    super()
    this.observable({
      user_list: [],
      member_list: [],
    })
    state = this
  }

  async findMemberList(mobile) {
    const url = `${config.api}/v1/admin/user?status=active&mobile=${mobile}`
    const resp = await http.get(url)

    const list = resp.body || []
    const { member_list } = this.toJS()

    const user_list = []
    for (const item of list) {
      const i = member_list.findIndex(it => it.user_id === item.user_id)
      if (i === -1) {
        user_list.push(item)
      }
    }

    runInAction(() => {
      state.user_list = user_list
    })
  }

  async getMemberList(market_id) {
    const url = `${config.api}/v1/admin/market/market-user/${market_id}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.member_list = list
    })
  }

  async addMember(market_id, user = {}) {
    const { user_id } = user
    const url = `${config.api}/v1/admin/market/market-user/${market_id}/${user_id}/add`
    await http.post(url)

    const { member_list, user_list } = this.toJS()
    member_list.push(user)

    const i = user_list.findIndex(it => it.user_id === user_id)
    if (i !== -1) {
      user_list.splice(i, 1)
    }

    runInAction(() => {
      state.member_list = member_list
      state.user_list = user_list
    })
  }

  async removeMember(market_id, user = {}) {
    const { user_id } = user
    const url = `${config.api}/v1/admin/market/market-user/${market_id}/${user_id}/remove`
    await http.put(url)

    const member_list = this.toJS().member_list
    const i = member_list.findIndex(it => it.user_id === user_id)
    if (i !== -1) {
      member_list.splice(i, 1)
    }

    runInAction(() => {
      state.member_list = member_list
    })
  }
}

export default new MarketMember()
