import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Tab } from 'components/display'

const MarketMenu = (props) => {
  const { id, selected, children } = props
  const navigate = useNavigate()

  const onChange = (val) => {
    let link
    switch (val) {
      case 'member':
        link = `/market/${id}/member`
        break
      case 'other':
        link = `/market/${id}/other`
        break
      case 'bank':
        link = `/market/${id}/bank`
        break
      case 'setting':
        link = `/market/${id}/setting`
        break
      default:
        link = `/market/${id}`
    }
    navigate(link)
  }

  return (
    <PageView>
      <Menu>
        <Tab
          menu={menu_list}
          selected={selected}
          onChange={onChange}
        />
      </Menu>

      {children}
    </PageView>
  )
}

const menu_list = [
  { label: 'ข้อมูลตลาด', value: 'information' },
  { label: 'ข้อมูลทั่วไป', value: 'other' },
  { label: 'ข้อมูลธนาคาร', value: 'bank' },
  { label: 'ตั้งค่า', value: 'setting' },
  { label: 'สมาชิกตลาด', value: 'member' }
]

const PageView = styled.div`
  width: 100%;
  max-width: 800px;
`

const Menu = styled.div`
  width: 540px;
  padding-bottom: 24px;
`

export default MarketMenu
