import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { message } from 'utils'
import { Content } from 'components/display'
import { Btn } from 'components/button'
import Loading from 'components/loading'

import Detail from './detail'

const ConsentDetail = (props) => {
  const [loading, setLoading] = useState()
  const { typ } = useParams()

  const { doc = {} } = props.consent.toJS()

  const onInit = useCallback(async (consent_type) => {
    try {
      setLoading(true)

      await props.consent.getConsent(consent_type)
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.consent])

  useEffect(() => {
    onInit(typ)
  }, [onInit, typ])

  const onSave = async () => {
    try {
      setLoading(true)

      await props.consent.saveConsent(typ, doc)
      message.success('บันทึกสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const getTitle = () => {
    switch (typ) {
      case 'privacy':
        return 'ข้อตกลงการใช้งาน Privacy'
      case 'terms':
        return 'ข้อตกลงการใช้งาน Terms and Conditions'
      case 'cookie':
        return 'ข้อตกลงการใช้งาน Cookie'
      default:
        return 'ไม่พบข้อมูลที่ต้องการ'
    }
  }

  const title = getTitle()
  const btn = <Btn loading={loading} text="บันทึก" onClick={onSave} />
  return (
    <Content title={title} right={btn}>
      <Loading loading={loading}>
        <Detail />
      </Loading>
    </Content>
  )
}


export default inject('consent')(observer(ConsentDetail))
