import styled from 'styled-components'

import Row from './Row'
import Col from './Col'

const Content = (props) => {
  const { children, title, right } = props

  const getTitle = () => {
    return (
      <TitleLine>
        <Row>
          <Col lg={20} md={18} xs={16}>
            <Title>{title}</Title>
          </Col>
          <Col lg={4} md={6} xs={8}>
            <Right>{right}</Right>
          </Col>
        </Row>
      </TitleLine>
    )
  }

  const text = title || right ? getTitle() : undefined
  return (
    <PageView>
      {text}
      <Body>
        {children}
      </Body>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 24px 40px 60px 24px;
`

const Body = styled.div`
  width: 100%;
`

const TitleLine = styled.div`
  padding-bottom: 10px;
`

const Title = styled.div`
  width: 100%;
  font-size: 20px;
`

const Right = styled.div`
  width: 100%;
  text-align: right;
`

export default Content
