import styled from 'styled-components'

const Icon = (props) => {
  const { img = false } = props

  return <MainImg src={img} />
}

const MainImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`

export default Icon
