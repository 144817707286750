import styled from 'styled-components'

const Card = (props) => {
  const { title, children } = props
  return (
    <PageView>
      <Title>{title || ''}</Title>
      {children}
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 10px 0px;
  border-radius: 10px;
`

const Title = styled.div`
  width: 100%;
  padding: 5px 0px;
  border: 0px solid ${(p) => p.theme.color_level.grey.light};
  border-bottom-width: 1px;
  margin-bottom: 20px;
`

export default Card
