import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { Content } from 'components/display'
import Table from 'components/table'
import Loading from 'components/loading'
import { message, helper } from 'utils'

import columns from './columns'
// import Filter from './Filter'

const ShopList = (props) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ text: '' })

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await props.shop.getList()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.market])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const display = props.shop.toJS().display
  const { list } = display

  return (
    <Content title="Merchant">
      <Loading loading={loading}>
        <Table
          list={list}
          columns={columns}
        />
      </Loading>
    </Content>
  )
}

export default inject('shop')(observer(ShopList))
