import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'

import { message, helper } from 'utils'
import { Content } from 'components/display'
import Table from 'components/table'
import { FilePicker } from 'components/button'
import Loading from 'components/loading'

const ProvinceList = (props) => {
  const [loading, setLoading] = useState(false)

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await props.province.getList()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.province])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const importContent = async (lines, err) => {
    if (!lines) {
      message.error(err)
      return
    }

    try {
      setLoading(true)
      await props.province.import(lines)
      await props.province.getList()

      message.success('บันทึกสำเร็จ')
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }

  const onImportFile = (file) => {
    helper.loadFile(file, importContent)
  }

  const onPostcode = (item) => {
    const postcode_list = item.postcode_list || []
    const name_list = postcode_list.map(it => it.postcode)
    return (
      name_list.join(', ')
    )
  }

  const onDistrict = (item) => {
    const district_list = item.district_list || []
    const name_list = district_list.map(it => it.district_name)
    return (
      name_list.join(', ')
    )
  }

  const { list } = props.province.toJS().display

  const columns = [
    {
      title: 'Seq',
      key: 'seq',
      size: {
        lg: 1, md: 4, xs: 12
      }
    },
    {
      title: 'Code',
      key: 'code',
      size: {
        lg: 2, md: 4, xs: 12
      }
    },
    {
      title: 'ชื่อ',
      key: 'province_name',
      size: {
        lg: 4, md: 12, xs: 12
      }
    },
    {
      title: 'เขต/อำเภอ',
      render: onDistrict,
      size: {
        lg: 9, md: 4, xs: 12
      }
    },
    {
      title: 'รหัสไปรษณีย์',
      render: onPostcode,
      size: {
        lg: 8, md: 4, xs: 12
      }
    }
  ]

  const btn = (
      <FilePicker
      loading={loading}
      text="นำเข้า"
      onSelect={onImportFile}
      extAccept=".xlsx"
    />
  )
  return (
    <Content title="Province" right={btn}>
      <Loading loading={loading}>
        <Table list={list} columns={columns} />
      </Loading>
    </Content>
  )
}

export default inject('province')(observer(ProvinceList))
