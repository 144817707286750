import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http, helper } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  consent_id: undefined,
  ver: '',
  content: '',
}

const name_list = [
  { name: 'seq', to: 'seq' },
  { name: 'code', to: 'code' },
  { name: 'province', to: 'province_name' },
  { name: 'district', to: 'district_name' },
  { name: 'tumbon', to: 'tumbon_name' },
  { name: 'postcode', to: 'postcode' },
]

let state
export class Province extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        list: [],
      },
      doc: cloneDeep(original),
      province_list: [],
    })
    state = this
  }

  async getList(force = true) {
    const current_list = this.toJS().display.list
    if (force === false && current_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/admin/province`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.list = list
    })
  }

  async import(lines = []) {
    const province_list = helper.excelListToJson(lines, name_list, { isFilter: true })

    let province = {}
    const list = []
    for (const item of province_list) {
      const {
        seq,
        code,
        province_name,
        district_name,
        tumbon_name,
      } = item

      const postcode = `${item.postcode}`

      // find province
      if (province.code !== code) {
        province = list.find(it => it.code === code)
        if (!province) {
          const tumbon = {
            tumbon_name,
            postcode,
          }

          const district = {
            district_name,
            tumbon_list: [ tumbon ]
          }
          province = {
            seq,
            code,
            province_name,
            district_list: [ district ],
            postcode_list: [ { district_name, postcode } ],
          }
          list.push(province)

          continue
        }
      }

      let district = province.district_list.find(it => it.district_name === district_name)
      if (!district) {
        district = {
          district_name,
          tumbon_list: []
        }
        province.district_list.push(district)
      }

      const tumbon = district.tumbon_list.find(it => it.tumbon_name === tumbon_name)
      if (!tumbon) {
        district.tumbon_list.push({
          tumbon_name,
          postcode,
        })
      }

      const isFound = province.postcode_list.find(c => c.postcode === postcode)
      if (!isFound) {
        province.postcode_list.push({ district_name, postcode })
      }
    }

    const url = `${config.api}/v1/admin/province/import`
    await http.post(url, { json: { list }})
  }

  async remove(id) {
    const url = `${config.api}/v1/admin/province/${id}/remove`
    await http.put(url)
  }

  convertProvince(list = []) {
    const province_list = list.map((province) => {
      const d_list = province.district_list || []

      const district_list = d_list.map((district) => {
        const t_list = district.tumbon_list || []
        const tumbon_list = t_list.map((tumbon) => {
          return {
            name: tumbon.tumbon_name,
            value: tumbon.tumbon_name,
            tag: tumbon,
          }
        })
        return {
          name: district.district_name,
          value: district.district_name,
          tag: { tumbon_list },
        }
      })

      const postcode_list = province.postcode_list || []

      return {
        name: province.province_name,
        value: province.province_name,
        tag: { district_list, postcode_list },
      }
    })

    return province_list
  }
  
  async getProvinceList() {
    const current_list = this.toJS().province_list
    if (current_list.length > 0) {
      return
    }

    const url = `${config.api}/v1/public/province`
    const resp = await http.get(url)

    const list = resp.body || []

    const province_list = this.convertProvince(list)
    runInAction(() => {
      state.province_list = province_list
    })
  }
}

export default new Province()
