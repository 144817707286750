import styled from 'styled-components'
import { Drawer } from 'antd'

import { Close } from 'icon'
import { PrimaryBtn } from 'components/button'

const BaseDrawer = (props) => {
  const {
    title,
    loading,
    children,
    visible,
    onClose,
    onSave,
  } = props

  const text = title ? <Title>{title}</Title> : ''
  return (
    <Drawer
      title={text}
      className="drawer-content"
      height="90%"
      placement={'right'}
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Btn onClick={onClose}>
        <Close />
      </Btn>
      <Body>
        <Html>
          {children}
        </Html>
        <Button>
          <SaveButton>
            <PrimaryBtn
              loading={loading}
              text="บันทึก"
              onClick={onSave}
            />
          </SaveButton>
        </Button>
      </Body>
    </Drawer>
  )
}

const Body = styled.div`
  position: relative;
  min-height: 90vh;
  margin-top: 48px;
`

const Html = styled.div`
  padding: 15px 20px;
  width: 100%;
  max-height: 82vh;
  overflow: auto;
`

const Title = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;
  color: ${(p) => p.theme.color.grey.high};
`

const Btn = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`

const SaveButton = styled.div`
  width: 250px;
  margin: 0 auto;
`

const Button = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`

export default BaseDrawer
