import styled from 'styled-components'
import { Row, Col } from 'antd'

import { Link } from 'components/link'

const TableView = (props) => {
  const { columns = [], data = {}, store, onLink } = props

  const getContent = (content, align) => {
    switch (align) {
      case 'center':
        return <Center>{content}</Center>
      case 'right':
        return <Right>{content}</Right>
      default:
        return <Left>{content}</Left>
    }
  }

  const url = onLink ? onLink(data) : undefined
  const list = columns.map((it, i) => {
    const { render, size, key, align, isLink = true } = it
    const name = render ? render(data, i, store) : data[key] || ''

    const content = getContent(name, align)
    if (isLink && onLink) {
      return (
        <Col key={i} {...size} >
          <Link to={url}>
            {content}
          </Link>
        </Col>
      )
    }

    return (
      <Col key={i} {...size} >
        {content}
      </Col>
    )
  })

  return (
    <PageView>
      <Row align="middle">
        {list}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  margin-top: 8px;
  padding: 10px 20px;
  border-radius: 12px;
  color: ${(p) => p.theme.color_level.grey.high};
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

const Center = styled.div`
  text-align: center;
`

const Right = styled.div`
  text-align: right;
  float: right;
`

const Left = styled.div`
  padding-right: 20px;
`

export default TableView
