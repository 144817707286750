import { runInAction } from 'mobx'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class User extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
        list: [],
      },
    })
    state = this
  }

  async getList({ index = 1, per_page = 20 }) {
    const url = `${config.api}/v1/admin/user?page=${index}&&per_page=${per_page}`
    const resp = await http.get(url)

    const list = resp.body || []

    runInAction(() => {
      state.display.page.index = index
      state.display.page.per_page = per_page
      state.display.list = list
    })
  }

  async getCount({ }) {
    const url = `${config.api}/v1/admin/user/count`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.display.page.total = counter || 0
    })
  }
}

export default new User()
