import styled, { useTheme } from 'styled-components'

import { Remove } from 'icon'
import { format } from 'utils'

import Invalid from './Invalid'

const Input = (props) => {
  const theme = useTheme()
  const {
    name,
    label,
    value = '',
    tag,
    readonly = false,
    disabled = false,
    clear = false,
    pattern,
    isNumber,
    onPressEnter,
    onChange = () => {},
    invalid,
    prefix,
    rows = 2,
  } = props

  const onValue = (evt) => {
    if (onChange) {
      let val = evt.target.value
      if (pattern) {
        val = format.clearPattern(val)
      }

      if (isNumber && val !== '') {
        const tmp = +val
        val = tmp === 0 || tmp ? val : value
      }

      onChange(val, name, tag)
    }
  }

  const onClear = () => {
    if (onChange) {
      onChange(isNumber ? '0' : '', name, tag)
    }
  }

  const invalidCss = invalid ? 'invalid' : ''
  const suffix =
    value === '' || clear === false ? undefined : (
      <Btn onClick={onClear}>
        <Remove color={theme.color_level.grey.high} />
      </Btn>
    )

  const text = pattern ? format.toPattern(value, pattern) : value
  return (
    <>
      <Content className={disabled ? 'disabled' : ''}>
        <Form className={disabled && text.length > 0 ? 'active' : ''}>
          <InputValue
            id={name}
            className={invalidCss}
            type="text"
            autoComplete="off"
            required
            rows={rows}
            disabled={disabled}
            value={text}
            onChange={onValue}
          />
          <Label htmlFor="name" className="label-name">
            <ContentName className={`content-name ${invalidCss}`}>
              {label}
            </ContentName>
          </Label>
          {suffix}
        </Form>
      </Content>
      <Invalid invalid={invalid} />
    </>
  )
}

const Content = styled.div`
  width: 100%;
  min-height: 52px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 6px 10px;

  &.disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Form = styled.div`
  position: relative;
  &.active textarea:disabled + .label-name .content-name,
  textarea:focus + .label-name .content-name,
  textarea:valid + .label-name .content-name {
    transform: translateY(-80%);
    color: ${(p) => p.theme.color_level.grey.medium};
    top: 14px;
  }
`

const InputValue = styled.textarea`
  width: 100%;
  height: 100%;
  padding-top: 14px;
  border: none;
  outline: none;

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }

  &:disabled {
    background-color: ${(p) => p.theme.input.disable};
  }
`

const Label = styled.label`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: 14px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
  }
`

const ContentName = styled.span`
  position: absolute;
  left: 0px;
  color: ${(p) => p.theme.color_level.grey.high};

  &.invalid {
    color: ${(p) => p.theme.color.error} !important;
  }
`

const Btn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 10px;
`

export default Input
