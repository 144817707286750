import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { validator, message } from 'utils'
import { Content } from 'components/display'
import { MarketBanking } from 'components/market'
import Loading from 'components/loading'

import MarketMenu from '../Menu'
import Save from '../Save'
import rules from './rules'

const MarketEditor = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validate, setValidate] = useState({})

  const { market_id } = useParams()

  const onLoad = useCallback(async (id) => {
    try {
      setLoading(true)
      await props.market.getDoc(id)
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.market])

  useEffect(() => {
    onLoad(market_id)
  }, [onLoad, market_id])

  const { doc = {} } = props.market.toJS()

  const onChange = (data) => {
    props.market.setMarket(data)
  }

  const onValidate = (valid = {}) => {
    setValidate({ ...valid })
  }

  const onSave = async () => {
    try {
      const checker = validator.process(rules, doc)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setSaving(true)

      await props.market.updateBank(doc)
      message.success()
    } catch (e) {
      message.error(e.message)
    }

    setSaving(false)
  }

  return (
    <Content>
      <MarketMenu id={market_id} selected={'bank'}>
        <Loading loading={loading}>
          <MarketBanking
            doc={doc}
            validate={validate}
            onChange={onChange}
            onValidate={onValidate}
          />
        </Loading>
      </MarketMenu>
      <Save loading={saving} onClick={onSave} />
    </Content>
  )
}

export default inject('market')(observer(MarketEditor))
