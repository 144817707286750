import { runInAction } from 'mobx'

import { http, timer, format } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state
export class Dashboard extends BaseStore {
  constructor() {
    super()
    this.observable({
      counter: {
        new_shop: 0,
        total_shop: 0,
        new_market: 0,
        total_market: 0,
        new_user: 0,
        total_user: 0,
        total_bookin_completed: 0,
        total_booking_cancelled: 0,
        total_income: 0,
        total_deposit: 0,
      },
      information: {
        label_list: [],
        counter_list: [],
        booking_list: [],
      },
    })
    state = this
  }

  count(date, list = []) {
    const t = 'DDMMYY'
    const result = list.filter(it => {
      const c = timer.get(it.created_at).format(t)

      return c === date
    })

    return result.length
  }

  async getUserCounter({ start_at } = {}) {
    const date = start_at.format('MMYYYY')
    const url = `${config.api}/v1/admin/user/dashboard/${date}`
    const resp = await http.get(url)

    const data = resp.body || {}
    return data
  }

  async getMarketCounter({ start_at } = {}) {
    const date = start_at.format('MMYYYY')
    const url = `${config.api}/v1/admin/market/dashboard/counter/${date}`
    const resp = await http.get(url)

    const data = resp.body || {}
    return data
  }

  setDashboard({ start_at }, marketData = {}, userData = {}) {
    let start = timer.get(start_at).startOf('month')
    const finish = timer.get(start_at).endOf('month')

    const label_list = []
    const completed_list = []
    const cancelled_list = []
    const market_list = []
    const shop_list = []
    const new_user_list = []

    const { counter, information } = marketData
    const { user_list } = userData
    const {
      booking_completed_list,
      booking_cancelled_list,
      new_market_list,
      new_shop_list,
    } = information

    const t = 'DDMMYY'
    while (start < finish) {
      label_list.push(format.toThaiMonth(start))
      const date = start.format(t)

      const completed = this.count(date, booking_completed_list)
      const cancelled = this.count(date, booking_cancelled_list)

      completed_list.push(completed)
      cancelled_list.push(cancelled)

      const market = this.count(date, new_market_list)
      const shop = this.count(date, new_shop_list)
      const newUser = this.count(date, user_list)

      market_list.push(market)
      shop_list.push(shop)
      new_user_list.push(newUser)
      start = start.add(1, 'day')
    }

    counter.new_user = userData.total_user || 0
    counter.total_user = userData.total || 0

    const booking_list = []
    booking_list.push({
      label: 'รายการจองสำเร็จ',
      data_list: completed_list,
    })

    booking_list.push({
      label: 'รายการจองยกเลิก',
      data_list: cancelled_list,
    })

    const counter_list = []
    counter_list.push({
      label: 'ผู้ใช้ใหม่',
      data_list: new_user_list,
    })

    counter_list.push({
      label: 'ตลาดใหม่',
      data_list: market_list,
    })

    counter_list.push({
      label: 'ร้านค้าใหม่',
      data_list: shop_list,
    })

    runInAction(() => {
      state.counter = counter
      state.information = {
        code: timer.getUnix(),
        label_list,
        booking_list,
        counter_list,
      }
    })
  }
}

export default new Dashboard()
