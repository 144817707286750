import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

const ColItem = (props) => {
  const { children, position } = props

  let content
  switch (position) {
    case 'center':
      content = <Center>{children}</Center>
      break
    case 'left':
      content = <Left>{children}</Left>
      break
    case 'right':
      content = <Right>{children}</Right>
      break
    case 'left-responsive':
      content = <LeftResponsive>{children}</LeftResponsive>
      break
    case 'right-responsive':
      content = <RightResponsive>{children}</RightResponsive>
      break
    case 'space':
      content = <SpaceView>{children}</SpaceView>
      break
    default:
      content = <PageView>{children}</PageView>
  }

  return <Col {...props}>{content}</Col>
}

const Center = styled.div`
  padding: 0px 8px;
`

const Left = styled.div`
  padding-right: 8px;
`

const Right = styled.div`
  padding-left: 8px;
`

const SpaceView = styled.div`
  padding: 0px 16px 16px 0px;
`

const LeftResponsive = styled.div`
  padding-right: 8px;

  ${(p) => p.theme.media.mobile} {
    padding-right: 0px;
    padding-bottom: 8px;
  }
`

const RightResponsive = styled.div`
  padding-left: 8px;

  ${(p) => p.theme.media.mobile} {
    padding-left: 0px;
    padding-top: 8px;
  }
`

const PageView = styled.div``

export default ColItem
