import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Content } from 'components/display'
import Table from 'components/table'
import Loading from 'components/loading'
import { PrimaryBtn } from 'components/button'
import { EditDrawer } from 'drawer'
import { message, helper, validator } from 'utils'

import columns from './columns'
import rules from './rules'
import Filter from './Filter'
import MarketCreator from './MarketCreator'

const original = {
  seq: 1,
  name: '',
  status: 'active',
  open_status: 'everyday',
  detail: '',
  img_list: [],
  shopper: undefined,
  price: 0,
  open_list: [],
  open_time_list: [],
}

const MarketList = (props) => {
  const [loading, setLoading] = useState(false)
  const [creating, setCreating] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [data, setData] = useState(cloneDeep(original))
  const [validate, setValidate] = useState({})

  const [filter, setFilter] = useState({ text: '', status: 'active' })

  const navigate = useNavigate()

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await props.market.getList()
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.market])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const onStatus = async (val, name, item) => {
    try {
      await props.market.updateStatus(item.market_id, val ? 'active' : 'inactive')
    } catch (e) {
      message.error(e.message)
    }
  }

  const onLink = (item = {}) => {
    return `/market/${item.market_id}`
  }

  const onCreate = () => setIsCreate(true)
  const onCloseCreate = () => setIsCreate(false)

  const onData = (val) => setData({ ...val })
  const onValidate = (val) => setValidate({ ...val })

  const onCreateMarket = async () => {
    try {
      const checker = validator.process(rules, data)
      if (checker.invalid) {
        setValidate(checker.errors)
        return
      }

      setCreating(true)

      const doc = await props.market.createMarket(data)

      setData(cloneDeep(original))
      setCreating(false)

      navigate(`/market/${doc.market_id}`)
    } catch (e) {
      message.error(e.message)
      setCreating(false)
    }
  }

  const getFilter = (list = []) => {
    const txt = filter.text.toLowerCase()
    const status = filter.status
    if (txt === '' && status === 'all') {
      return list
    }

    return list.filter(it => {
      const name = it.name.toLowerCase()
      if (txt !== '' && status !== 'all') {
        return name.includes(txt) && status === it.status
      } else if (txt !== '') {
        return name.includes(txt)
      }

      return status === it.status
    })
  }

  const display = props.market.toJS().display

  const { list } = display

  const market_list = getFilter(list || [])
  const btn = (
    <PrimaryBtn
      loading={creating}
      text="เพิ่มตลาด"
      onClick={onCreate}
    />
  )

  return (
    <Content title="Market" right={btn}>
      <Filter filter={filter} onChange={(val) => setFilter({...filter})} />

      <Loading loading={loading}>
        <Table
          list={market_list}
          columns={columns}
          onLink={onLink}
          store={{ onStatus }}
        />
      </Loading>
      <EditDrawer visible={isCreate} onClose={onCloseCreate} onSave={onCreateMarket}>
        <MarketCreator
          data={data}
          validate={validate}
          onChange={onData}
          onValidate={onValidate} />
      </EditDrawer>
    </Content>
  )
}

export default inject('market')(observer(MarketList))
