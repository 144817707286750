import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Link } from 'components/link'
import { Dot } from 'components/display'
import { format, message } from 'utils'

const PendingBooking = (props) => {
  const [loading, setLoading] = useState(false)

  const display = props.booking.toJS().display
  const { page, list } = display

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)

      const condition = {
        status: 'create',
        index: 1,
        per_page: 10,
      }
      await Promise.all([
        props.booking.getList(condition),
        props.booking.getCount(condition),
      ])
    } catch (e) {
      message.error(e.message)
    }
    setLoading(false)
  }, [props.booking])

  useEffect(() => {
    onGetList()
  }, [onGetList])

  const content = list.map((it, i) => {
    const { code, market_name } = it
    return (
      <Row key={i}>
        <Line>
          <Text>{code.toUpperCase()}</Text>
          <Text>{market_name}</Text>
        </Line>
        <Dot />
      </Row>
    )
  })
  return (
    <PageView>
      <Title>รายการรอการยืนยัน</Title>
      <Header>
        <Text>รหัส</Text>
        <Text>ตลาด</Text>
      </Header>
      {content}
      <Footer>
        <Total>ทั้งหมด {format.toDigi(page.total)} รายการ</Total>
        <Btn>
          <Link to="/booking">ดูทั้งหมด</Link>
        </Btn>
      </Footer>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 8px 20px;
  background-color: white;
  border-radius: 10px;

  ${(p) => p.theme.media.tablet} {
    margin-top: 16px;
  }
`

const Title = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.color.blue};
  padding-bottom: 8px;
`

const Header = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`

const Row = styled.div`
  width: 100%;
  padding-bottom: 8px;
`

const Line = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`

const Text = styled.div`
  width: 50%;
`

const Footer = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
`

const Total = styled.div`
  width: fit-content;
`

const Btn = styled.div`
  width: fit-content;
  color: ${(p) => p.theme.color.blue};
  text-decoration: underline;
`

export default inject('booking')(observer(PendingBooking))
