import { format } from './format'

export class Market {
  getOpenTimePattern(txt = '') {
    return txt.length > 3 ? 'xx:xx' : 'x:xx'
  }

  toOpenTime(doc = {}) {
    const open_time_list = doc.open_time_list || []

    const list = open_time_list.map((it) => {
      const { start_at, finish_at } = it
      const pStart = this.getOpenTimePattern(start_at)
      const pFinish = this.getOpenTimePattern(finish_at)

      const start = format.toPattern(start_at, pStart)
      const finish = format.toPattern(finish_at, pFinish)

      return `${start}-${finish}`
    })

    return list.join(' ,')
  }

  getBookingTypeName(bookingType) {
    switch (bookingType) {
      case 'daily':
      case 'booking_daily':
        return 'รายวัน'
      case 'monthly':
      case 'booking_monthly':
        return 'รายเดือน'
      default:
        return 'รายรอบ'
    }
  }
}


export const market = new Market()
export default market
